<div>
    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>

    <ng-template #contentList>

        <div class="col-md-12 mb-3 aling-items-center">
            <label class="control-label">Export</label>
    
            <app-export [info]="array"></app-export>
    
        </div>
        <div class="col-md-12">
            <app-lista *ngIf="!loading" [viewEndpoint]="viewEndpoint" [viewbtn]="viewbtn" [buttonsHide]="buttonsHide" [formSections]="formSections" [title]="modalTitle" [array]="array" [endPoint]="endPoint" [form]="form" [editForm]="editForm" (refresh)="refresh()" (formEditEmitter)="receiveEdit($event)" (itemIdEmitter)="receiveID($event)" [activateOlt]="activateOlt" > </app-lista>
        </div>
    </ng-template>
</div>

<app-modal *ngIf="!loading" [title]="modalTitle" [formSections]="formSections" [id]="id" [endPoint]="endPoint" [editForm]="editForm" [form]="form" [selectOptions]="options" (dataEmitter)="receiveData($event)" (activateOlt)="recieveActivate($event)"></app-modal> 
