<div class="d-flex flex-column aling-items-center" *ngIf="showFilter">
  <!-- <app-filter (statusEmitter)="aplicarFiltro($event)"></app-filter> -->
  <!--FILTRO PARA COMPONENE-->
  <div class="d-flex p-2 aling-items-center">
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label"> Select OLT</label>
        <ng-select
          [items]="itemsOlts"
          (change)="selectOlt(selectedOlt)"
          [clearable]="false"
          notFoundText="No data found"
          placeholder="Select OTL"
          bindLabel="name"
          bindValue="name"
          [(ngModel)]="selectedOlt"
        >
        </ng-select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="control-label">Filter</label>
        <input
          type="text"
          class="form-control"
          placeholder="SN, MAC, IP, name, address"
          name="filters"
          [(ngModel)]="filters"
          (ngModelChange)="onInputChange()"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="status" class="control-label">Status / Signal</label>
        <ul class="pagination">
          <li
            class="status-filter mr-2"
            #online
            value="online"
            (click)="selectStatus('online')"
            title="Online"
          >
            <span
              ><i
                class="fa fa-globe fa-sm text-green btn btn-light"
                [ngClass]="filters2 == 'online' ? 'active' : ''"
              ></i
            ></span>
          </li>
          <li
            class="status-filter mr-2"
            #powerFail
            value="powerfail"
            title="Power Fail"
            (click)="selectStatus('power fail')"
          >
            <span
              ><i
                class="fa fa-plug fa-sm text-grey btn btn-light"
                [ngClass]="filters2 == 'power fail' ? 'active' : ''"
              ></i
            ></span>
          </li>
          <li
            class="status-filter mr-2"
            #los
            value="los"
            title="Los Of Signal"
            (click)="selectStatus('LOS')"
          >
            <span
              ><i
                class="fa fa-chain-broken fa-sm text-red btn btn-light"
                [ngClass]="filters2 == 'LOS' ? 'active' : ''"
              ></i
            ></span>
          </li>
          <li
            class="status-filter mr-2"
            #offline
            value="offline"
            title="Offline"
            (click)="selectStatus('offline')"
          >
            <span
              ><i
                class="fa fa-globe fa-sm text-red btn btn-light"
                [ngClass]="filters2 == 'offline' ? 'active' : ''"
              ></i
            ></span>
          </li>
          <li
            class="status-filter mr-2"
            #disabled
            value="disabled"
            title="Disabled"
            (click)="selectStatus('Disabled')"
          >
            <span
              ><i
                class="fa fa-ban fa-sm text-grey btn btn-light"
                [ngClass]="filters2 == 'Disabled' ? 'active' : ''"
              ></i
            ></span>
          </li>
          <li
            class="status-filter mr-2"
            value="very good"
            (click)="selectStatus('Very good')"
            title="Very good"
          >
            <span
              ><i
                class="fa fa-signal fa-md text-success btn btn-light"
                [ngClass]="filters2 == 'very good' ? 'active' : ''"
              ></i
            ></span>
          </li>
          <li
            class="status-filter mr-2"
            value="warning"
            title="Warning"
            (click)="selectStatus('Warning')"
          >
            <span
              ><i
                class="fa fa-signal fa-md text-warning btn btn-light"
                [ngClass]="filters2 == 'warning' ? 'active' : ''"
              ></i
            ></span>
          </li>
          <li
            class="status-filter mr-2"
            value="critical"
            title="Critical"
            (click)="selectStatus('Critical')"
          >
            <span
              ><i
                class="fa fa-signal fa-md text-danger btn btn-light"
                [ngClass]="filters2 == 'critical' ? 'active' : ''"
              ></i
            ></span>
          </li>
        </ul>
      </div>
    </div>
    <!--Para exportar-->
    <div class="col-md-2 aling-items-center">
      <label class="control-label">Export</label>
      <app-export [info]="getVisibleItems()" [columns]="headers"></app-export>
    </div>
  </div>
</div>
<div class="col-md-12">
  <div class="card bd-primary">
    <div class="card-header bg-dark text-white">
      <span>{{ title }} list</span>
      <a
        class="btn btn-success float-right"
        data-toggle="modal"
        data-target="#newModal"
        *ngIf="!buttonsHide"
      >
        Add {{ title }}
      </a>
    </div>
    <div class="card-body pd-sm-30">
      <div class="table-responsive">
        <table
          class="table-striped table border"
          *ngIf="array.length > 0; else arrayEmpty"
        >
          <thead>
            <tr>
              <ng-container *ngIf="viewbtn">
                <th>View</th>
              </ng-container>

              <ng-container *ngIf="autorizeBtn">
                <th>Authorize</th>
              </ng-container>

              <ng-container *ngFor="let header of headers">
                <th scope="col">
                  {{
                    header.includes("_") ? header.split("_").join(" ") : header
                  }}
                </th>
              </ng-container>

              <ng-container *ngIf="!buttonsHide">
                <th>Actions</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of getVisibleItems()">
              <td *ngIf="viewbtn" class="text-center">
                <a
                  [routerLink]="['/dashboard/' + viewEndpoint]"
                  [queryParams]="{ id: item.id }"
                  class="btn btn-success"
                  >View</a
                >
              </td>

              <td *ngIf="autorizeBtn" class="text-center">
                <a
                  data-toggle="modal"
                  data-target="#authorizeModal"
                  class="btn btn-success text-white"
                  >Authorize</a
                >
              </td>

              <ng-container *ngFor="let key of getObjectKeysWithoutId(item)">

                <ng-container [ngSwitch]="key">

                  <ng-container *ngSwitchCase="'status'">
                    <td class="text-center">
                      <a [title]="item[key]">
                        <i [class]="switchStatus(item[key])"></i>
                      </a>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchCase="'signal'">
                    <td class="text-center" id="">
                      <i [class]="switchSignal(item[key])">
                        <p class="invisible">{{ item[key] }}</p>
                      </i>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <td>{{ item[key] }}</td>
                  </ng-container>

                </ng-container>

              </ng-container>

              <td *ngIf="!buttonsHide">
                <button
                  *ngIf="title == 'OLTS'"
                  title="Activate OLT"
                  (click)="syncOlt(item.id)"
                  [class]="
                    item.olt_active == 'Active'
                      ? 'btn btn-success btn-sm mx-2'
                      : item.olt_active == 'Pending'
                      ? 'btn btn-warning btn-sm mx-2'
                      : 'btn btn-danger btn-sm mx-2'
                  "
                  [disabled]="item.olt_active != 'Inactive' || searching"
                >
                  <i
                    *ngIf="!searching && item.olt_active != 'Pending'"
                    class="fa fa-power-off text-white"
                  ></i>
                  <i
                    *ngIf="!searching && item.olt_active == 'Pending'"
                    class="fa fa-clock-o text-white"
                  ></i>
                  <i
                    *ngIf="searching"
                    class="fa fa-spinner fa-spin margin-left"
                  ></i>
                </button>

                <a
                  (click)="edit(item.id)"
                  data-toggle="modal"
                  data-target="#editModal"
                  class="btn btn-secondary btn-sm mx-2"
                >
                  <i class="fa fa-pencil-square-o text-white"></i>
                </a>
                <a
                  class="btn btn-danger btn-sm mx-2"
                  data-toggle="modal"
                  (click)="toDelete(item.id)"
                  data-target="#deleteModal"
                >
                  <i class="fa fa-trash text-white"></i>
                </a>
                </td>
            </tr>
          </tbody>
        </table>
        <ng-template #arrayEmpty>
          <h5 class="text-center">No results</h5>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="btn-pagination align-items-center">
    <button
      class="btn btn-list"
      (click)="previousPage()"
      [disabled]="currentPage === 1"
    >
      Previous
    </button>
    <span class="number">{{ currentPage }} of {{ getTotalPages() }}</span>
    <button
      class="btn btn-list"
      (click)="nextPage()"
      [disabled]="currentPage === getTotalPages()"
    >
      Next
    </button>
  </div>
</div>
