<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button
      class="nav-link active"
      id="nav-vpb-tab"
      data-toggle="tab"
      data-target="#nav-vpn"
      type="button"
      role="tab"
      aria-controls="nav-vpn"
      aria-selected="true"
    >
      VPN tunnels
    </button>
    <button
      class="nav-link"
      id="nav-tr-tab"
      data-toggle="tab"
      data-target="#nav-tr"
      type="button"
      role="tab"
      aria-controls="nav-tr"
      aria-selected="false"
    >
      TR069 Profiles
    </button>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div
    class="tab-pane fade show active"
    id="nav-vpn"
    role="tabpanel"
    aria-labelledby="nav-vpn-tab"
  >
    <div class="accordion" id="accordion1">
      <div class="card mb-2 mt-2">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="btn btn-block d-flex justify-content-between"
              type="button"
              data-toggle="collapse"
              data-target="#vpn1"
              aria-expanded="false"
              aria-controls="vpn1"

              (click)="icons.uno = !icons.uno"
            >
              Info


              <i [ngClass]="icons.uno? icons.up : icons.down"></i>

            </button>


          </h2>
        </div>

        <div
          id="vpn1"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="card-body bg-light">
            Some placeholder content for the first accordion panel. This panel
            is shown by default, thanks to the <code>.show</code> class.
          </div>
        </div>
      </div>

      <div class="card mb-2 mt-2">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button
              class="btn btn-block d-flex justify-content-between collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#vpn2"
              aria-expanded="true"
              aria-controls="vpn2"

              (click)="icons.dos = !icons.dos"

            >
              Tunnel status

              <i [ngClass]="icons.dos? icons.up : icons.down"></i>

            </button>
          </h2>
        </div>
        <div
          id="vpn2"
          class="collapse show"
          aria-labelledby="headingTwo"
          data-parent="#accordion1"
        >
          <div class="card-body bg-light overflow-x-scroll">
            <table class="table table-striped table-hover" id="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User/tunnelName</th>
                  <th>Status</th>
                  <th>Subnet</th>
                  <th>Connected subnets</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody class="bg-light">
                <!-- Tabla -->
                <tr
                  class="valign-center"
                  *ngFor="let item of vpnTunnels; let i = index"
                >
                  <td class="text-dark">{{ i + 1 }}</td>
                  <td class="text-dark">{{ item.userName }}</td>
                  <td class="text-dark">
                    <span
                      class="fw-bolder"
                      [ngClass]="
                        item.status == 'Connected'
                          ? 'text-primary'
                          : 'text-danger'
                      "
                      >{{ item.status }}</span
                    >
                    <ng-container *ngIf="item.status == 'Connected'">{{
                      " (" + item.statusInfo.tunnelIp + ")"
                    }}</ng-container>
                    <br />
                    <ng-container *ngIf="item.status == 'Connected'">
                      TunnelIp: {{ item.statusInfo.tunnelIp }} <br />
                      <i class="bi bi-file-earmark-arrow-up-fill"></i>
                      {{ item.statusInfo.upSpeed }}
                      <i class="bi bi-file-earmark-arrow-down-fill"></i>
                      {{ item.statusInfo.downSpeed }} <br />
                      Since: {{ item.statusInfo.since }}
                    </ng-container>
                  </td>
                  <td class="text-dark">{{ item.subnet }}</td>
                  <td class="text-dark">{{ item.connnectedSubnets }}</td>
                  <td>
                    <button type="button" class="btn btn-primary" disabled>
                      <i class="bi bi-file-earmark-code"></i>Mikrotik VPN setup
                    </button>
                    <button type="button" class="btn btn-success" disabled>Edit</button>
                    <button type="button" class="btn btn-danger" disabled>Del</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="tab-pane fade"
    id="nav-tr"
    role="tabpanel"
    aria-labelledby="nav-tr-tab"
  >
    <div class="accordion" id="accordion2">
      <div class="card mb-2 mt-2">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="btn btn-block d-flex justify-content-between"
              type="button"
              data-toggle="collapse"
              data-target="#tr1"
              aria-expanded="false"
              aria-controls="tr1"

              (click)="icons.tres = !icons.tres"
            >
              Info

              <i [ngClass]="icons.tres? icons.up : icons.down"></i>



            </button>
          </h2>

        </div>

        <div
          id="tr1"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
          #collapse
        >
          <div class="card-body bg-light">
            Some placeholder content for the first accordion panel. This panel
            is shown by default, thanks to the <code>.show</code> class.
          </div>
        </div>
      </div>

      <div class="card mb-2 mt-2">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button
              class="btn btn-block d-flex justify-content-between collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#tr2"
              aria-expanded="true"
              aria-controls="tr2"

              (click)="icons.cuatro = !icons.cuatro"

            >
              Defined profiles

              <i [ngClass]="icons.cuatro? icons.up : icons.down"></i>

            </button>
          </h2>
        </div>
        <div
          id="tr2"
          class="collapse show"
          aria-labelledby="headingTwo"
          data-parent="#accordion2"
        >
          <div class="card-body bg-light overflow-x-scroll">
            <table class="table table-striped table-hover" id="table">
              <thead>
                <tr>
                  <th>Profile name</th>
                  <th>CWMP ACS</th>
                  <th>Status</th>
                  <th>OLTs</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody class="bg-light">
                <!-- Tabla -->
                <tr class="valign-center" *ngFor="let item of trProfiles">
                  <td class="text-dark">{{ item.profileName }}</td>
                  <td class="text-dark">{{ item.CWMPACS }}</td>
                  <td class="text-dark">
                    CWMP:
                    <i
                      [ngClass]="
                        item.status.cwmp
                          ? 'bi bi-check-circle-fill'
                          : 'bi bi-x-circle-fill'
                      "
                    ></i>
                    NBI:
                    <i
                      [ngClass]="
                        item.status.nbi
                          ? 'bi bi-check-circle-fill'
                          : 'bi bi-x-circle-fill'
                      "
                    ></i>
                    FS:
                    <i
                      [ngClass]="
                        item.status.fs
                          ? 'bi bi-check-circle-fill'
                          : 'bi bi-x-circle-fill'
                      "
                    ></i>
                  </td>

                  <td>
                    <select name="olts" id="olts">
                      <option value="OLT-BARINAS">OLT-BARINAS</option>
                      <option value="OLT-BARINAS">OLT-BARINAS</option>
                      <option value="OLT-BARINAS">OLT-BARINAS</option>
                      <option value="OLT-BARINAS">OLT-BARINAS</option>
                    </select>
                  </td>
                  <td>
                    <button type="button" class="btn btn-success" disabled>Set OLTs</button>
                    <button type="button" class="btn btn-primary" disabled>View</button>
                    <button type="button" class="btn btn-primary" disabled>Files</button>
                    <button type="button" class="btn btn-danger" disabled>Del</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
