import { Component, inject, Input } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { Modal } from 'src/app/core/interfaces/Modal.interface';


@Component({
  selector: 'app-odbs',
  templateUrl: './odbs.component.html',
  styleUrls: ['./odbs.component.scss']
})
export class OdbsComponent {

  @Input() Modal!: Modal;


  form: FormGroup;
  editForm: FormGroup;

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  modalTitle: string = '';
  modalText: string = '';
  buttonSave: string = '';
  buttonClose: string = '';
  loading = false;
  array: any;
  close: boolean = false;
  idOdb: any;
  ElementSelect: any;
  options: any = [];
  selectedItemId: number | undefined;
  endPoint: string = 'odbs';
  id: any;
  formSections:any;
  buttonsHide:boolean = false;
  

  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      zone_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      latitude: new FormControl('', [Validators.minLength(5)]),
      longitude: new FormControl('', [Validators.minLength(5)]),
      nr_of_ports: new FormControl('', [Validators.required, Validators.minLength(1)])
    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      zone_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      latitude: new FormControl('', [Validators.minLength(5)]),
      longitude: new FormControl('', [Validators.minLength(5)]),
      nr_of_ports: new FormControl('', [Validators.required, Validators.minLength(1)])
    });
  }

  


  async ngOnInit() {
    let estructura = {
      title: "ODBS",
      text: "Esto es una simple prueba",
      saveButton: "Save",
      closeButton: "Close"
    }

    this.Modal = estructura;

    this.modalTitle = this.Modal.title

    this.modalText = this.Modal.text

    this.buttonSave = this.Modal.saveButton

    this.buttonClose = this.Modal.closeButton

    this.loading = true;

    const data = await this.smartOltService.getData('odbs/listing');
 
 
    this.formRefresh();

    const zones = await this.smartOltService.getData('locations/listing');
    this.options = zones.data;

    //Espera la carga de las opciones

    this.formSections = [
      {
        title: 'ODBS',
        controlName: 'name',
        placeholder: 'Nombre del ODBS',
        type: 'text',
      },
      {
        title: 'Zone',
        controlName: 'zone_id',
        placeholder: 'Select zone',
        type: 'select',
        selectOptions: this.options,
      },
      {
        title: 'Number of ports',
        controlName: 'nr_of_ports',
        placeholder: 'Numero de puertos ODBS',
        type: 'number',
      },
      {
        title: 'ODBS Latitude',
        controlName: 'latitude',
        placeholder: 'ODBS latitude',
        type: 'text',
      },
      {
        title: 'ODBS Longitude',
        controlName: 'longitude',
        placeholder: 'ODBS Longitude',
        type: 'text',
      },
    ];
    
    if (data.data.length > 0) {
      this.loading = false;
      this.array = data.data;
    } else {
      this.loading = false;
      this.array = [];
    }
  }

  formRefresh() {
    this.form.reset();
  }


  async edit(id: bigint) {
    this.id=id
    const response = await this.smartOltService.getById('odbs', id);
    if (response) {
      this.form.reset();
      this.idOdb = response.data[0].id;
      this.options = response.data[0].zone;
    }
  }

  get name() {
    return this.form.get('name');
  }
  get zone_id() {
    return this.form.get('zone_id');
  }
  get numPorts() {
    return this.form.get('nr_of_ports');
  }

    
  receiveID(itemId: number) {
    this.id = itemId;
  }

  receiveEdit(editForm:any){
    this.editForm = editForm;
  }

  receiveData(data: any){
    this.array = data;
  }

}
