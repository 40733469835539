<div class="row">
    <div class="col-md-12 mb-3">
        <button class="btn btn-primary" routerLink="/dashboard/settings/olts"><i class="fa fa-reply"></i> Return</button>
    </div>
    <hr>
    <div class="col-md-12">
        <form (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="name">OLT name</label>
                    <input type="text" class="form-control" [formControl]="form.controls.name" placeholder="OLT name">
                    <div *ngIf="form.controls.name.errors && form.controls.name.dirty">
                        <div class="text-danger" *ngIf="form.controls.name.errors?.['required']">Invalid data.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="oltIp">OLT IP</label>
                    <input type="text" class="form-control" [formControl]="form.controls.ip" placeholder="OLT IP">
                    <div *ngIf="form.controls.ip.errors && form.controls.ip.dirty">
                        <div class="text-danger" *ngIf="form.controls.ip.errors?.['required']">Invalid data.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>PON TYPE</label>
                    <ng-select [items]="itemsPonTypes" notFoundText="No se encontraron datos"
                        [formControl]="form.controls.support_pon_type" placeholder="Select" bindLabel="name"
                        bindValue="id" [(ngModel)]="selectedPonType">
                    </ng-select>
                    <div *ngIf="form.controls.support_pon_type.errors && form.controls.support_pon_type.dirty">
                        <div class="text-danger" *ngIf="form.controls.support_pon_type.errors?.['required']">Invalid data.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="telnet_ssh_tcp_port">Telnet/SSH TCP port</label>
                    <input type="number" class="form-control" [formControl]="form.controls.telnet_ssh_tcp_port"
                        placeholder="Telnet/SSH TCP port">
                    <div *ngIf="form.controls.telnet_ssh_tcp_port.errors && form.controls.telnet_ssh_tcp_port.dirty">
                        <div class="text-danger" *ngIf="form.controls.telnet_ssh_tcp_port.errors?.['required']">Invalid data.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="ipvt">IPTV</label>
                    <input type="text" class="form-control" [formControl]="form.controls.ipvt" placeholder="IPTV">
                    <div *ngIf="form.controls.ipvt.errors && form.controls.ipvt.dirty">
                        <div class="text-danger" *ngIf="form.controls.ipvt.errors?.['required']">Invalid data.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="oltHardwareVersion">OLT hardware version</label>
                    <input type="text" class="form-control" [formControl]="form.controls.oltHardwareVersion"
                        placeholder="OLT hardware version">
                    <div *ngIf="form.controls.oltHardwareVersion.errors && form.controls.oltHardwareVersion.dirty">
                        <div class="text-danger" *ngIf="form.controls.oltHardwareVersion.errors?.['required']">Invalid data.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="oltSwVersion">OLT SW version</label>
                <input type="text" class="form-control" [formControl]="form.controls.oltSwVersion"
                    placeholder="OLT SW version">
                <div *ngIf="form.controls.oltSwVersion.errors && form.controls.oltSwVersion.dirty">
                    <div class="text-danger" *ngIf="form.controls.oltSwVersion.errors?.['required']">Invalid data.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="snmp_port">SNMO PORT</label>
                <input type="text" class="form-control" [formControl]="form.controls.snmp_port"
                    placeholder="SNMO PORT">
                <div *ngIf="form.controls.snmp_port.errors && form.controls.snmp_port.dirty">
                    <div class="text-danger" *ngIf="form.controls.snmp_port.errors?.['required']">Invalid data.
                    </div>
                </div>
            </div>
            <div class="float-right">
                <button type="submit" class="btn btn-success mx-2" *ngIf="!updateBtn" [disabled]="form.invalid">Save</button>
                <button type="button" class="btn btn-success mx-2" (click)="onUpdate()" [disabled]="form.invalid" *ngIf="updateBtn">Update</button>
                <button type="button" routerLink="/dashboard/settings/olts" class="btn btn-light">Cancel</button>
            </div>
        </form>
    </div>
</div>