<div class="sh-pagetitle" *ngIf="bar">
  <div *ngIf="rightSideItem === 'search'" class="input-group">
    <input #formValue (keyup)="$event.keyCode === 13 ?SalidaClickMenuEvent('search',formValue):''" type="search" class="form-control" placeholder="Search">
    <span class="input-group-btn">
      <button class="btn" (click)="SalidaClickMenuEvent('search',formValue)"><i class="fa fa-search"></i></button>
    </span>
  </div>
  <div *ngIf="rightSideItem === 'button'" class="input-group">
    <div [style]="StyleDefault">
      <!-- <button class="btn btn-primary mg-b-10" (click)="SalidaClickMenuEvent('button')" (click)="testEvent()"><i [class]="IconDefault"></i>{{ TitleButtonDefault }}</button>-->
    </div>
  </div>
  <div *ngIf="rightSideItem === 'not set'" class="input-group">
    <div [style]="StyleDefault">
      <!-- Without rightSideItem -->
    </div>
  </div>
  <div *ngIf="Ruta" class="sh-pagetitle-left">
    <div class="sh-pagetitle-icon">
      <ng-content select="[icon]"></ng-content>
    </div>
    <div class="sh-pagetitle-title">
      <ng-content select="[title]"></ng-content>
      <ng-content select="[subtitle]"></ng-content>
    </div>
  </div>
</div>