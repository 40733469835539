<div class="container">
    <div class="col-12">
        <form (ngSubmit)="onSubmit()">
            <div class="form-group row">
                <label for="" class="col-2">OLT</label>
                <div class="col-10 ">
                    <ng-select [items]="itemsOLT" [loading]="loading" notFoundText="No data" placeholder="Select"
                        bindLabel="name" bindValue="id" [formControl]="form.controls.olt_id ">
                    </ng-select>
                    <div class="col-12" *ngIf="form.controls.olt_id.errors && form.controls.olt_id.dirty">
                        <div class="text-danger" *ngIf="form.controls.olt_id.errors?.['required']">Required data.</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-2">PON TYPE</label>
                <div class="col-10">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="ponType"
                            [formControl]="form.controls.pon_type" id="gpon" value="gpon">
                        <label class="form-check-label" for="gpon">GPON</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="ponType"
                            [formControl]="form.controls.pon_type" id="epon" value="epon">
                        <label class="form-check-label" for="epon">EPON</label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="board" class="col-2">Board</label>
                <div class="col-10">
                    <input type="number" class="form-control" id="board" [formControl]="form.controls.board"
                        placeholder="Board (optional)">
                </div>
                <div class="col-12" *ngIf="form.controls.board.errors && form.controls.board.dirty">
                    <div class="text-danger" *ngIf="form.controls.board.errors?.['required']">Required data.</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="port" class="col-2">Port</label>
                <div class="col-10">
                    <input type="number" [formControl]="form.controls.port" id="port" class="form-control"
                        placeholder="Port (optional)">
                </div>
                <div class="col-12" *ngIf="form.controls.port.errors && form.controls.port.dirty">
                    <div class="text-danger" *ngIf="form.controls.port.errors?.['required']">Required data.</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="sn" class="col-2">SN</label>
                <div class="col-10">
                    <input type="text" [formControl]="form.controls.sn" id="sn" class="form-control">
                </div>
                <div class="col-12" *ngIf="form.controls.sn.errors && form.controls.sn.dirty">
                    <div class="text-danger" *ngIf="form.controls.sn.errors?.['required']">Required data.</div>
                    <div class="text-danger" *ngIf="form.controls.sn.errors?.['minlength']">This data must have 5 characters</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-2">ONU type</label>
                <div class="col-10 ">
                    <ng-select [items]="itemsOnuTypes" [loading]="loading" *ngIf="itemsOnuTypes" [bindValue]="'id'"
                        [bindLabel]="'name'" notFoundText="No data" placeholder="Seleccionar"
                        [formControl]="form.controls.onu_type" >
                    </ng-select>
                </div>
                <div class="col-12" *ngIf="form.controls.onu_type.errors && form.controls.onu_type.dirty">
                    <div class="text-danger" *ngIf="form.controls.onu_type.errors?.['required']">Required data.</div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-2"></div>
                <div class="col-10">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck">
                        <label class="form-check-label" for="gridCheck">
                            Check me out
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-2">ONU mode</label>
                <div class="col-10">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="onuMode" id="routing"
                            [formControl]="form.controls.mode" value="routing">
                        <label class="form-check-label" for="routing">Routing</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="onuMode" id="bridging"
                            [formControl]="form.controls.mode" value="bridging">
                        <label class="form-check-label" for="bridging">Bridging</label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-2"></div>
                <div class="col-10">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="svlan">
                        <label class="form-check-label" for="svlan">
                            Use SVLAN-ID
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-2">User VLAN-ID</label>
                <div class="col-10">
                    <ng-select [items]="itemsVLAN" [loading]="loading" notFoundText="No data" placeholder="Seleccionar"
                        [formControl]="form.controls.service_port_vlan" >
                    </ng-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-2">Zone</label>
                <div class="col-10">
                    <ng-select [items]="itemsZone" [loading]="loading" *ngIf="itemsZone" bindValue="id" bindLabel="name"
                        notFoundText="No data" placeholder="Select"
                        [formControl]="form.controls.zone " >
                    </ng-select>
                </div>
                <div class="col-12" *ngIf="form.controls.zone.errors && form.controls.zone.dirty">
                    <div class="text-danger" *ngIf="form.controls.zone.errors?.['required']">Required data.</div>
                </div>
            </div>
            <div class="form-group row">
                    <label for="" class="col-2">ODB (Splitter)</label>
                    <div class="col-10">
                        <ng-select [items]="itemsSplitter" [loading]="loading" *ngIf="itemsSplitter" [bindValue]="'id'"
                            [bindLabel]="'name'" notFoundText="No data" placeholder="Seleccionar"
                            [formControl]="form.controls.odb">
                        </ng-select>
                    </div>
                    <div class="col-12" *ngIf="form.controls.odb.errors && form.controls.odb.dirty">
                        <div class="text-danger" *ngIf="form.controls.odb.errors?.['required']">Este campo es
                            requerido.</div>
                    </div>
                </div> 
            <div class="form-group row">
                <label for="" class="col-2">ODB port</label>
                <div class="col-10">
                    <ng-select [items]="itemsPort" [loading]="loading" *ngIf="itemsPort" bindValue="id" bindLabel="name"
                        notFoundText="No data" placeholder="Select"
                        [formControl]="form.controls.port" >
                    </ng-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="" class="col-2">Download speed</label>
                <div class="col-10">
                    <ng-select [items]="itemSpeed" [loading]="loading" *ngIf="itemSpeed" bindValue="id" bindLabel="name"
                        notFoundText="No data" placeholder="Select"
                        [formControl]="form.controls.speed_downloaded_id" >
                    </ng-select>
                </div>
                <div class="col-12"
                    *ngIf="form.controls.speed_downloaded_id.errors && form.controls.speed_downloaded_id.dirty">
                    <div class="text-danger" *ngIf="form.controls.speed_downloaded_id.errors?.['required']">Required data.</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="uploadSpeed" class="col-2">Upload speed</label>
                <div class="col-10">
                    <ng-select [items]="itemSpeed" [loading]="loading" *ngIf="itemSpeed" bindValue="id" bindLabel="name"
                        id="uploadSpeed" notFoundText="No data" placeholder="Select"
                         [formControl]="form.controls.speed_up_id">
                    </ng-select>
                </div>
                <div class="col-12" *ngIf="form.controls.speed_up_id.errors && form.controls.speed_up_id.dirty">
                    <div class="text-danger" *ngIf="form.controls.speed_up_id.errors?.['required']">Required data.</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="name" class="col-2">Name</label>
                <div class="col-10">
                    <input type="text" id="name" class="form-control" [formControl]="form.controls.name">
                </div>
                <div class="col-12" *ngIf="form.controls.name.errors && form.controls.name.dirty">
                    <div class="text-danger" *ngIf="form.controls.name.errors?.['required']">Required data.</div>
                </div>
                <div class="text-danger" *ngIf="form.controls.name.errors?.['minlength']">This data must have 5 characters</div>
            </div>
            <div class="form-group row">
                <label for="address" class="col-2">Addres or comment</label>
                <div class="col-10">
                    <input type="text" class="form-control" id="address" placeholder="Addres or comment (optional)"
                        [formControl]="form.controls.address">
                </div>
                <div class="col-12" *ngIf="form.controls.address.errors && form.controls.address.dirty">
                    <div class="text-danger" *ngIf="form.controls.address.errors?.['required']">Required data</div>
                </div>
                <div class="text-danger" *ngIf="form.controls.address.errors?.['minlength']">This data must have 5 characters</div>
            </div>
            <div class="form-group row">
                <label for="onuExternal" class="col-2">ONU external ID</label>
                <div class="col-10">
                    <input type="text" class="form-control" id="onuExternal" [formControl]="form.controls.onu_external"
                        placeholder="Use the unique ONU external ID with API or billing systems">
                </div>
                <div class="col-12" *ngIf="form.controls.onu_external.errors && form.controls.onu_external.dirty">
                    <div class="text-danger" *ngIf="form.controls.onu_external.errors?.['required']">Required data</div>
                </div>
                <div class="text-danger" *ngIf="form.controls.onu_external.errors?.['minlength']">This data must have 5 characters</div>
            </div>
            <div class="form-group row">
                <div class="col-2"></div>
                <div class="col-10">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gps">
                        <label class="form-check-label" for="gps">
                            Use GPS
                        </label>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <button type="submit" class="btn btn-primary mr-3" >Save</button>
                <a [routerLink]="['/dashboard/unconfigured']" class="btn btn-secondary" >Return</a>
            </div>
        </form>
    </div>
</div>