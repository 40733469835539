import { Component } from '@angular/core';

@Component({
  selector: 'app-master-logs',
  templateUrl: './master-logs.component.html',
  styleUrls: ['./master-logs.component.scss']
})
export class MasterLogsComponent {
  public Atributos = { 'title': 'Historial de Logs', 'Description': 'Listado control de los registros e incidencias' }
}
