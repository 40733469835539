<!-- TODOS LOS INPUT SE MANEJAN BAJO lA INTERFAZ IControl.interface.ts  -->

<!-- INPUT -->
<!--<ng-container
*ngIf="control.tag === 'input' && [
    'text',
    'password',
    'email',
    'number',
    'date',
    'search',
    'tel',
    'url'
  ].includes(control.attributes?.type!)">

  <div [class]="{'input-group': control.buttonGroup || control.buttonLabel}">
    <span *ngIf="control.buttonLabel" class="input-group-addon tx-size-sm lh-2">
      <i [class]="control.buttonLabel.icon"></i>
      {{ control.buttonLabel.content }}
    </span>
    <input class="form-control"
      [type]="control.attributes?.type"
      [name]="control.name"
      [attr.pattern]="control.attributes?.pattern"
      [attr.value]="control.attributes?.value"
      [attr.placeholder]="control.attributes?.placeholder?.toUpperCase()"
      [attr.required]="control.attributes?.required"
      [attr.disabled]="control.attributes?.disabled"
      [formControlName]="control.name">
    <span *ngIf="control.buttonGroup" class="input-group-btn">
      <button class="btn bd bg-white tx-gray-600">
        <i [class]="{
          'fa': true,
          'fa-plus': control.buttonGroup === 'add',
          'fa-search': control.buttonGroup === 'search'
        }"></i>
      </button>
    </span>
  </div>

</ng-container>

<ng-container [formGroup]="parentFormGroup"> 
  <ng-container *ngIf="control.tag === 'input' && (control.attributes?.type === 'checkbox')">
    <label
      [class]="{rdiobox: control.attributes?.type === 'checkbox'}"
      *ngFor="let option of control.data">
      <input
        (click)="manuallySetControlValue(option.value)"
        [name]="control.name"
        [type]="control.attributes?.type"
        [value]="option.value"
        [attr.required]="control.attributes?.required"
        [formControlName]="control.name">
      <span>{{ option.name }}</span>
    </label>
  </ng-container>
</ng-container>-->

<section>
  <ng-container>
    <div class="ch1">
      <label for="c">Check1</label>
      <input type="checkbox" id="c">
    </div>
  </ng-container>
</section>


