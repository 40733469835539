<div class="slideout-content add-new-user modal-content pt-0">
  <!-- Modal to add new user starts-->
  <!-- <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">

    </div>
  </div> -->
  <form style="width: 100%;" (ngSubmit)="(submit($event))" #formValidation>
    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
      (click)="toggleSidebar('new-user-sidebar')"> -->
      <!-- × -->
    <!-- </button> -->
    <div class="modal-header mb-1">
      <!-- APP TITLE -->
      <h5 class="modal-title" id="exampleModalLabel">{{param.TitleHead}}</h5>
      <!-- END APP TITLE -->
      <button type="button" class="close" data-dismiss="modal" aria-label="Close"
      (click)="sendMessage('cerrar')">
      <i class="icon ion-close"></i>
      <!-- × -->
    </button>
    </div>
    <div *ngIf="ConseguiData" class="modal-body flex-grow-1">

      <div *ngFor="let formControlItem of param.Order" class="form-group">
        <span [ngSwitch]="formControlItem.TipoDato">
           <label *ngIf=" formControlItem.HeadListName !='' && formControlItem.TipoDato !='img' && formControlItem.ShowInsert"  class="form-label"
            for="basic-icon-default-fullname">{{formControlItem.HeadListName + (formControlItem.Requerido ? '*' : '')}}</label>

          <p *ngSwitchCase="'Diccionario'">            
            <app-field-dinamic [FieldDynamic]="formControlItem.Campo" (ValueSalida)="EventoDinamic($event,formControlItem.Campo)" [idUserEmp]="userID" [value]="formData.vars[formControlItem.Campo] || ''"></app-field-dinamic>
          </p>

          <p *ngSwitchCase="'Numeric'">
            <app-numeric [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-numeric>
          </p>

          <p *ngSwitchCase="'String'">
            <app-string [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-string>
          </p>

          <p *ngSwitchCase="'Date'">
            <app-date [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-date>
          </p>

          <p *ngSwitchCase="'Name'">
            <app-name [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-name>
          </p>

          <p *ngSwitchCase="'Password'">
            <app-password [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-password>
          </p>

          <p *ngSwitchCase="'Movil'">
            <app-movil [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-movil>
          </p>
            <!-- [class.error]="newUserForm.submitted && formControlItem.Campo.invalid" -->

          <p *ngSwitchCase="'Email'">
            <app-email [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-email>
          </p>

          <p *ngSwitchCase="'Area'">
            <app-area [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-area>
          </p>

          <!-- <img *ngSwitchCase="'img'" src="assets/img/anonimo.png" width="60" /> -->

          <p *ngSwitchCase="'Avatar'" class="AvatarStyle">
            <app-avatar [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-avatar>
          </p>

          <p *ngSwitchCase="'Switch'">
            <app-switch [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-switch>
          </p>

          <p *ngSwitchCase="'Color'">
            <app-color [data]="formControlItem" [formData]="formData" [value]="formData.vars[formControlItem.Campo] || ''"></app-color>
          </p>

          <p *ngSwitchCase="'DependencySelection'">
            <app-dependency-selection></app-dependency-selection>
          </p>

          <p *ngSwitchCase="''">
            <app-select></app-select>
          </p>
        </span>
      </div>

      <div class="row">
        <div class="col-8">
          <button type="submit" class="btn btn-primary btn-block mr-1"
          rippleEffect>
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
          Guardar
        </button>
        </div>
        <div class="col-4">
          <button type="reset" class="btn btn-outline-secondary btn-block"
          rippleEffect
          (click)="sendMessage('resetear')">
          <i class="fa fa-refresh"></i>
          Resetear
        </button>
        </div>
      </div>

    </div>

    <div *ngIf="!ConseguiData">
      <h2>Estimado cliente no se pudo cargar la data, comuniquese con soporte</h2>
    </div>
  </form>
  <!-- Modal to add new user Ends-->
</div>
