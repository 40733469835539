import { Component, Input } from '@angular/core';
import { Chart } from 'node_modules/chart.js/auto';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {

  @Input() idChart: any;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() data: any;
  @Input() labels: any;
  @Input() type: any;
  @Input() color!: string;
  @Input() cant: any;

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.chart1();
  }

  chart1(){
    new Chart(this.idChart, {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: [{
          label: this.subtitle,
          data: this.data,
          backgroundColor: this.color,
          borderColor: this.color,
          borderWidth: 1,
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

}

