import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { dbFullService } from 'src/app/services/dbFull.service';

interface ICoreEstructure {
  TitleHead: any,
  Table: any,
  Estructura: any[],
  Inputs: any[],
  Value:any[]//"idUserEmp_3jKgVz"
}

const steps = [
  'cb_EmpresaSucursal',
  'cb_SucursalDir'
];


@Component({
  selector: 'app-modal-sucursales',
  templateUrl: './modal-sucursales.component.html',
  styleUrls: ['./modal-sucursales.component.scss']
})
export class ModalSucursalesComponent implements OnInit {
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();
  @Input() selectedElement: any;
  public dataSelectedItem!: any;

  public createElement: boolean = false;
  public openCreateElement: boolean = false;
  public showClientData: boolean = false;
  public openCreateELementInit: boolean = true;
  public tableName: string = 'cb_EmpresaSucursal';
  public DataFormOpen: any;
  public Atributos: any = { 'title': 'Sucursales', 'Description': 'Listado control de las sucursales de empresas' }
  public updateData: any;
  private estructura: any;
  public optionsMenu=[
    {id:2, name:'Editar', icon:'icon ion-edit'},
    {id:1, name: 'Eliminar', icon:'icon ion-trash-a'}
  ]

  public DataFormOpenArray: ICoreEstructure[] = [];


  constructor( private dbFull: dbFullService, private router: Router ) {}

  ngOnInit(): void {
    this.getEstructura();
    this.dataSelectedItem = {
      Campo: 'idEmpConv',
      id: this.selectedElement.idEmpConv
    };
    this.createFormSteps();
  }

  sendMessage() {
    this.SalidaClickMenu.emit()
  }

  getEstructura() {
    this.dbFull.GetAllDataCampo( 'cb_Estructura', 'TableName', this.tableName ).then( Estructura  => {
      const est = Estructura.filter((element: any) => element.PK === 1);
      

      this.DataFormOpen = {
        TitleHead: this.Atributos?.title,
        Table: this.tableName,
        Estructura: Estructura,
        Inputs: [],
        Value:[]//"idUserEmp_3jKgVz"
      }
      this.Atributos.Estructura = Estructura;
    })
  }

  showCreateForm() {
    this.createElement = true;
  }

  toCloseCreateElement( element?: any ) {
    this.createElement = false;
    this.reloadCurrentRoute();
  }

  async createFormSteps(){
    const FullDBObj = new dbFullService();

    for (const table of steps) {
      const estructure = await FullDBObj.GetAllDataCampo("cb_Estructura", "TableName", table)

      const core = {
        TitleHead: this.Atributos?.title,
        Table: table,
        Estructura: estructure,
        Inputs: [],
        Value:[]//"idUserEmp_3jKgVz"
      }

      this.DataFormOpenArray.push(core)
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}
