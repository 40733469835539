<input
    type="password"
    class="form-control"
    [placeholder]="data.PlaceHolder + (!!data.Requerido ? '*' : '')"
    [name]="data.Campo"
    [required]="data.Requerido"
    (keypressed)="HandlerOnKey()"
    (change)="HanderOnChange($event)"
    (blur)="HanderOnChange($event)"
    [maxLength]="data.MaxLen"
    [hidden]="!data.ShowInsert"
    [value]="value"
    [pattern]="data.ExpresionReg"
/>
<div style="color: red" *ngIf="formData.error[data.Campo]">{{formData.error[data.Campo]}}</div>

<!-- <input
    type="password"
    class="form-control"
    [placeholder]="data.PlaceHolder"
    [name]="data.Campo"
    [(ngModel)]="param.Value[i]"
    [class.error]="newUserForm.submitted && param.Campos[i].invalid"
    #[param.Campos[i]]="ngModel"
    [required]="param.Required[i]"
/> -->