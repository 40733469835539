import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {



  //Value es la variable que se va a filtrar
  //Arg y arg2 son los argumentos con los que se va a comparar y filtrar
  transform(value: any, arg: any, arg2?: number): any {

    //Primero se le coloca un minimo de argumentos para empezar a evaluar si no, se hara el return
    if ((arg === '' || arg.length < 4) && (arg2 === null)) return value;
    //result es el resultado devuelto en la tabla
    var result = [];
    //Aux se utilizara para almarcenal el resultado si result ya tiene datos guardados
    var aux = [];

    arg = arg.toLowerCase();
    for (const search of value) {
      if (search.name && search.name.toLowerCase().indexOf(arg) > -1) {
        result.push(search);
      }
      else if (search.sn && search.sn.toLowerCase().indexOf(arg) > -1) {
        result.push(search);
      }
      else if (search.odb_name && search.odb_name.toLowerCase().indexOf(arg) > -1) {
        result.push(search);
      }
      else if (search.zone_name && search.zone_name.toLowerCase().indexOf(arg) > -1) {
        result.push(search);
      }
      else if (search.onu_type_name && search.onu_type_name.toLowerCase().indexOf(arg) > -1) {
        result.push(search);
      }
      else if (search.mode && search.mode.toLowerCase().indexOf(arg) > -1) {
        result.push(search);
      }
      else if (search.olt_name && search.olt_name.toLowerCase().indexOf(arg) > -1) {
        result.push(search);
      }
    };

    if ((result.length > 0) && (arg2! != null)) {

      for (const search of result) {
        if (search.status && search.status.toLowerCase().indexOf(arg2) > -1) {
          aux.push(search);
        }
        else if (search.signal && search.signal.toLowerCase().indexOf(arg2) > -1) {
          aux.push(search);
      };
      result = aux;
      }
    }
    else if (arg2! != null) {
      for (const search of result) {
        if (search.status && search.status.toLowerCase().indexOf(arg2) > -1) {
          aux.push(search);
        }
        else if (search.signal && search.signal.toLowerCase().indexOf(arg2) > -1) {
          aux.push(search);
      };
      result = aux;
      }
    }

    return result;
  }

}