<textarea
    type="text"
    class="form-control"
    [value]="formData.vars[data.Campo] || ''"
    [name]="data.Campo"
    [placeholder]="data.PlaceHolder + (!!data.Requerido ? '*' : '')"
    [required]="data.Requerido ? 'required' : ''"
    (keypressed)="HandlerOnKey()"
    (change)="HanderOnChange($event)"
    (blur)="HanderOnChange($event)"
    rows="3"
    [maxLength]="data.MaxLen"
    [hidden]="!data.ShowInsert"
    [value]="value"
>
</textarea>
<div style="color: red" *ngIf="formData.error[data.Campo]">{{formData.error[data.Campo]}}</div>

the left side of this nullish coalescing operation does no include 'null'