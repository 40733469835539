<div class="mb-5">
<div>
    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>

    <ng-template #contentList>
        <app-lista [showFilter]="showFilter" [buttonsHide]="buttonsHide" [title]="title" [array]="data" [endPoint]="endPoint" [autorizeBtn]="autorizeBtn"> </app-lista>
    </ng-template>

    <app-modal [endPoint]="endPoint"  ></app-modal>
</div>