import { Component } from '@angular/core';

@Component({
  selector: 'app-vpn-tunnels',
  templateUrl: './vpn-tunnels.component.html',
  styleUrls: ['./vpn-tunnels.component.scss']
})
export class VpnTunnelsComponent {

  vpnTunnels = [
    {
      userName: "tunnel1",
      status: "Disconnected",
      statusInfo: {
        tunnelIp: "10.69.69.5",
        upSpeed: "13 MB",
        downSpeed: "5 MB",
        since: "2023-07-25 05:06"
      },
      subnet: "10.69.69.0/24",
      connnectedSubnets: ["172.29.0.0/17"]
    },
    {
      userName: "tunnelperu",
      status: "Connected",
      statusInfo: {
        tunnelIp: "10.69.69.5",
        upSpeed: "15 MB",
        downSpeed: "5 MB",
        since: "2023-07-25 05:06"
      },
      subnet: "10.69.69.0/24",
      connnectedSubnets: ["10.5.16.0/22"]
    },
    {
      userName: "tunnelTorreA1",
      status: "Connected",
      statusInfo: {
        tunnelIp: "10.69.69.5",
        upSpeed: "14 MB",
        downSpeed: "4 MB",
        since: "2023-07-25 05:06"
      },
      subnet: "10.69.69.0/24",
      connnectedSubnets: ["172.20.144.0/24","172.18.3.0/24"]
    },
    {
      userName: "tunnelPRADO",
      status: "Connected",
      statusInfo: {
        tunnelIp: "10.69.69.5",
        upSpeed: "21 MB",
        downSpeed: "6 MB",
        since: "2023-07-25 19:46"
      },
      subnet: "10.69.69.0/24",
      connnectedSubnets: ["172.28.191.0/17"]
    },
    {
      userName: "tunnelTOCUYITO",
      status: "Connected",
      statusInfo: {
        tunnelIp: "10.69.69.5",
        upSpeed: "8 MB",
        downSpeed: "2 MB",
        since: "2023-07-25 19:46"
      },
      subnet: "10.69.69.0/24",
      connnectedSubnets: ["172.29.0.0/17"]
    },
    {
      userName: "tunnelTorreA12",
      status: "Connected",
      statusInfo: {
        tunnelIp: "10.69.69.5",
        upSpeed: "10 MB",
        downSpeed: "3 MB",
        since: "2023-07-25 05:06"
      },
      subnet: "10.69.69.0/24",
      connnectedSubnets: ["172.29.0.0/17","172.20.144.0/24"]
    },
    {
      userName: "tunnelCIUDADALIANZA",
      status: "Connected",
      statusInfo: {
        tunnelIp: "10.69.69.5",
        upSpeed: "13 MB",
        downSpeed: "5 MB",
        since: "2023-07-25 19:46"
      },
      subnet: "10.69.69.0/24",
      connnectedSubnets: ["172.29.0.0/17"]
    }

  ];

  trProfiles = [
    {
      profileName: "SmartOLT",
      CWMPACS: "http://10.69.69.1:14501",
      status: {
        cwmp: 1,
        nbi: 1,
        fs: 0
      }
    }
  ]


  icons = {
    uno: false,
    dos: false,
    tres: false,
    cuatro: false,
    up: 'bi bi-caret-up-fill',
    down: 'bi bi-caret-down-fill'
  }

}
