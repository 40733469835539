<app-select-list *ngIf="TipoComponente=='1'" [Field]="DataSendComponent" [TitleSow]="TitleStr" [idUserEmp]="idUserEmp" (ValueSalida)="GetDataSelect($event)"></app-select-list>

<!-- Radio -->
<app-radio-list *ngIf="TipoComponente=='2'" [Field]="DataSendComponent" [TitleSow]="TitleStr" (ValueSalida)="GetDataSelect($event)"></app-radio-list>

<!-- Check -->
<app-check-list *ngIf="TipoComponente=='3'" [Field]="DataSendComponent" [TitleSow]="TitleStr" (ValueSalida)="GetDataSelect($event)"></app-check-list>

<!-- Select with Insert -->
<app-extensible-select *ngIf="TipoComponente=='7'" [value]="value" [Field]="DataSendComponent" [TitleShow]="TitleStr" [TableName]="TableName" (ValueSalida)="GetDataSelect($event)" ></app-extensible-select>

<!-- Multi Selct -->
<app-extensible-select *ngIf="TipoComponente=='8'" [multiple]="true" [value]="value" [Field]="DataSendComponent" [TitleShow]="TitleStr" [TableName]="TableName" (ValueSalida)="GetDataSelect($event)" ></app-extensible-select>

<!-- Card -->
<app-cards *ngIf="TipoComponente=='9'" [value]="value" [Fields]="DataSendComponent" [TitleShow]="TitleStr" (ValueSalida)="GetDataSelect($event)" ></app-cards>

<!-- Tab -->
<app-taps *ngIf="TipoComponente=='10'" [value]="value" [Fields]="DataSendComponent" [TitleShow]="TitleStr" (ValueSalida)="GetDataSelect($event)" ></app-taps>


<!-- Lista Tipo Chat -->
<app-list-table *ngIf="TipoComponente=='11'" [value]="value" [Fields]="DataSendComponent" [TitleShow]="TitleStr" (ValueSalida)="GetDataSelect($event)"></app-list-table>