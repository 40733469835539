<div class="slideout-content add-new-user modal-content pt-0" >
  <div class="modal-body flex-grow-1">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="sendMessage()">
      <i class="icon ion-close"></i>
    </button>
    
    <app-list-table2 *ngIf="Atributos?.tipo!='lista'" [TableName]="tableName" [options]="optionsMenu" [Atributos]="Atributos" [Estructura]="DataFormOpen" [isNecesaryUpdate]="updateData" [renderFrom]="'sucursales'" [dataForSucursales]="dataSelectedItem"></app-list-table2>
    
    <div class="create-btn-container">
      <button class="btn btn-primary rounded-circle"
              (click)="showCreateForm()">
        <i class="fa fa-pencil"></i>
      </button>
    </div>
  </div>
</div>

<!-- ngClass="{{ renderFrom === 'sucursales' ? 'list-table3' : 'list-table2 list-table2-showsize' }}" -->
<div *ngIf="createElement"
          class="list-table3">
         <app-new-user-sidebar-stepper [params]="DataFormOpenArray" (SalidaClickMenu)="toCloseCreateElement($event)" [idEmpConvCreate]="selectedElement.idEmpConv"></app-new-user-sidebar-stepper>
</div>