import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/core/interfaces/IModule.interface';

@Component({
  selector: 'app-nav-btns-right',
  templateUrl: './nav-btns-right.component.html',
  styleUrls: ['./nav-btns-right.component.scss']
})
export class NavBtnsRightComponent {

  @Input() navBtnsRight!: IModule | any;
  @Input() profileDdwnBtns!: IModule | any;

  public notifications = [
    {
      "id": 1,
      "title": "Suzzeth Bungaos",
      "descrip": "tagged you and 18 others in a post.",
      "date": "October 03, 2017 8:45am",
      "img": "assets/img/img8.jpg",
      "order": 1,
      "styleRules": {
        "icon": "icon ion-ios-person"
      },
      "childrenModules": []
    },
    {
      "id": 2,
      "title": "Mellisa Brown",
      "descrip": null,
      "date": "October 02, 2017 12:44am",
      "img": "assets/img/img9.jpg",
      "order": 2,
      "styleRules": {
        "icon": "icon ion-ios-gear"
      },
      "childrenModules": []
    },
    {
      "id": 3,
      "title": null,
      "descrip": "tagged you and 18 others in a post.",
      "date": "October 01, 2017 10:20pm",
      "img": "assets/img/img10.jpg",
      "order": 3,
      "styleRules": {
        "icon": "icon ion-ios-download"
      },
      "childrenModules": []
    },
    {
      "id": 4,
      "title": "Mellisa Brown",
      "descrip": "tagged you and 18 others in a post.",
      "date": "October 01, 2017 6:08pm",
      "img": "assets/img/img5.jpg",
      "order": 4,
      "styleRules": {
        "icon": "icon ion-ios-star"
      },
      "childrenModules": []
    }
  ]

}
