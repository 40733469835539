import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import $ from 'jquery';
import 'src/assets/lib/datatables/jquery.dataTables.js'; // using the one from npm presents issues



@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {
  @Input() Field: any;
  @Input() TitleSow: any;
  @Input() idUserEmp: string = '';
  @Output() ValueSalida = new EventEmitter<string>();
  public tableId: string = '';
  public tableClasses: string[] = [];
  public ArrayDataIn: any[]=[]
  public thead: any[]=[]
  public TempTestTableData: any
  public valueUserEmp: string = '';
  constructor() { 
    
  } 

 
  ngOnInit(): void {
    this.ArrayDataIn = this.Field;
    this.gerUserEmp(this.ArrayDataIn);
    let ArgValues: any[]=[]
    const argHead = ["First Name2", "Last name", "Position", "Start date", "Salary", "E-mail"]
    const value = ["Tiger", "Nixon", "System Architect", "2011/04/25", "$320,800", "t.nixon@datatables.net"]
    const value2 = ["Tiger", "Nixon", "System Architect", "2010/04/25", "$320,800", "t.nixon@datatables.net"]
    ArgValues.push(value)
    ArgValues.push(value2)   
    const DatosSend = { title: "El titulo", head: argHead, data: ArgValues } 

    this.TempTestTableData = DatosSend
    this.thead = ["ID", "Nombre", "Rif", "Vendedor", "URL", "Status", "Prioridad"]

    /* $("#selectList").select2({
      theme: "bootstrap4",
      placeholder: "Select an option"
    });
    $("#selectList").on("change", () => {
      console.log($(this).val());
    }); */
  }

  setValue(Value: string) {
    this.ValueSalida.emit(Value);
  }
  
  gerUserEmp( data: any[] ) {

    if( data.length > 0 ) {
      for( let enterprise of data ) {
        if( enterprise.EmpresaUser.length > 0 ) {
          const test = enterprise.EmpresaUser.filter(( user: any ) => user.idUserEmp === this.idUserEmp );
          test.length === 1 ? this.valueUserEmp = enterprise.value : this.valueUserEmp = ''; 
        } return;
      }
    }
  }

}
