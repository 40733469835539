import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent {
  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = {vars:{}, error: {}};
  // @Output() outdata = new EventEmitter<{name: string, value: string, isInvalid: boolean}>();
  
  HandlerOnKey() {
    delete this.formData.error[this.data.Campo];
  }
  
  HanderOnChange(event: any) {
    // console.log(event.target.value)
    
    // let value = event.target.value;

    // const invalidRegExp = Boolean(this.data.ExpresionReg && RegExp(this.data.ExpresionReg).test(value) === false);
    // const invalidMaxLength = Boolean(this.data.MaxLen !== null && value.length > this.data.MaxLen);
    // const invalidRequired = Boolean(this.data.Requerido && value.length === 0);

    // // fix data
    // if(invalidMaxLength) {
    //   value = value.slice(0, this.data.MaxLen);
    // }
    
    // event.target.value = value;
    
    // if(invalidRegExp) {
    //   this.msgInvalid = `El valor no cumple con la estructura deseada`;
    // }
    // else if(invalidRequired) {
    //   this.msgInvalid = `Campo requerido*`
    // }
    // else {
    //   delete this.msgInvalid;
    // }

    // console.log({name: this.data.Campo, value: value, isInvalid: Boolean(this.msgInvalid)})
    // this.outdata.emit({name: this.data.Campo, value: event.target.value, isInvalid: Boolean(this.msgInvalid)});
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = event.target.value;
  }
}
