import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { SmartOltService } from 'src/app/services/smart-olt.service';


@Component({
  selector: 'app-scroll-card',
  templateUrl: './scroll-card.component.html',
  styleUrls: ['./scroll-card.component.scss']
})
export class ScrollCardComponent {

  @Input() cardTitle: string = ''; 
  @Input() info: any = [];
  @Input() type: any;
  @Input() icon?: string = '';
  @Input() filter?: boolean = false;
  @Output() filterData = new EventEmitter<any>();

  filteredOlt: any = '';
  filterText: string = '';
  filteredDataTable!: any[];

  constructor(private smartOltService: SmartOltService, private router: Router) {}


  ngOnInit() {
    this.transformData();
  }

  redirect(item: any){

    this.router.navigate(["/dashboard/settings/olts/view"], {queryParams: {id: item.id, path: 'home'}});

  }

  emitData(data: any){
    this.filteredOlt = data
    this.filterData.emit(data.id);
  }

  minutesPassed(d: any) {

    let diff =(new Date().getTime() - new Date(d).getTime()) / 1000;
    diff /= 60;

    let res = Math.abs(Math.round(diff))
    
    let hours = 0;

    if(res > 60 && res < 1440){

      hours = Math.floor(res / 60 );

      return `${hours} hours and ${res - (hours*60)} minutes ago`

    } else if (res > 1440) {

      return `${Math.floor(res/1440)} days ago`

    } else if (res < 60 ){

      return `${res} minutes ago`

    } else {
       
      return `${res * 60} seconds ago`

    }        
 }

  transformData(){

    if(this.filter){

      this.smartOltService.reset.subscribe( (data: any)=>{

        if(data){

          this.filteredOlt = '';
        }
      });

    }

    this.filteredOlt = '';

    if(this.info.length){

      if(this.info[0].name == undefined && this.type != 'table') {

        this.info = this.info.map((i: any) => {
  
  
          i.name = i.id + ' ' + i.accion;
  
          i.info = this.minutesPassed(i.time) + '...';
  
          return i
  
        })
        
      } else if(this.info[0].name == undefined && this.type == 'table'){
  
        let onus = this.info[0];
  
        let olts = this.info[1];
  
        this.info = olts.map((olt: any) => {
  
          let obj = {
            oltName: olt.name,
            boardPort: '13/16',
            onus: 5,
            los: 1,
            power: 18,
            cause: 'Power fail',
            since: this.minutesPassed('2023-08-01T20:21:50.826Z')
          }
  
          return obj;
  
        });

      
      }
    }

   

  }

  // ngOnDestroy() {
  //   this.smartOltService.reset.unsubscribe();
  // }

}
