<div class="pd-10 bd">
    <ul class="nav nav-pills flex-column flex-md-row" role="tablist">
      <li class="nav-item" *ngFor="let tap of Fields">
        <button type="button" [class]="'nav-link' + (getValue() === tap.value ? ' active' : '')" (click)="onClick(tap.value)" style="border-radius: 0px;">
            {{tap.datoShow}}
        </button>
      </li>
    </ul>
</div><!-- pd-10 -->

<!-- <div class="tabs-container">
    
</div> -->