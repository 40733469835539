<div ngClass="{{ renderFrom === 'sucursales' ? 'sh-pagebody2' : 'sh-pagebody' }}">
    <div *ngIf="showEdit"
         ngClass="{{ renderFrom === 'sucursales' ? 'list-table3' : 'list-table2 list-table2-showsize' }}">
         <app-new-user-sidebar-stepper  [element]="this.element" [params]="DataFormOpenArray" (SalidaClickMenu)="toCloseCreateElement($event)" [optionToExecute]="'edit'"></app-new-user-sidebar-stepper>
         <!-- <app-new-user-sidebar  [element]="this.element" [param]="param" (SalidaClickMenu)="toCloseCreateElement($event)"></app-new-user-sidebar> -->
        </div>
    <!-- Aqui se debe aplicar ngClass / tanto aqui como arriba-->
    <!-- <div *ngIf="!showEdit && !openCreateELementInit" class="list-table2 list-table2-hiddensize">
        <app-new-user-sidebar  (SalidaClickMenu)="toCloseCreateElement()"></app-new-user-sidebar>
    </div> -->

    <div *ngIf="showEdit || showSucursales" (click)="toCloseCreateElement()" class="list-table2-show" style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: absolute; width: 100%; height: 100%; z-index: 80;"></div>
    <div *ngIf="!openCreateELementInit" (click)="toCloseCreateElement()" class="list-table2-hidden" style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: absolute; width: 100%; height: 100%; z-index: 80;"></div>
   
    <div *ngIf="showSucursales"
         ngClass="{{ !showSucursales && !openCreateELementInit ? 'list-table2 list-table2-hiddensize': 'list-table2 list-table2-showsize' }}">
        <app-modal-sucursales (SalidaClickMenu)="toCloseCreateElement()" [selectedElement]="element"></app-modal-sucursales>
    </div>
   
    <div class="card bd-primary">
      <div class="card-header bg-primary tx-white">{{ Atributos?.title }}</div>
      <div class="card-body pd-sm-30">
        
        <!-- <div class="d-inline-block"> -->
            <div (click)="toOpenMenu()" style="position: relative; float: right; cursor: pointer; color:dodgerblue">
                FILTRO <i class="icon ion-settings"></i> 
            </div>
            <div *ngIf="showMenu" class="menu-dropdown" aria-labelledby="dropdownBasic1">
                <button *ngFor="let head of FieldAndHead?.head" ngbDropdownItem>{{ head }}</button> 
            </div>

		<!-- </div> -->
        <p class="mg-b-20 mg-sm-b-30">{{ Atributos?.Description }}</p>
 
        <div class="table-wrapper" >
            <div [class]="['table-responsive'].concat(tableClasses || '')">
                <table id="datatable2" class="table display responsive nowrap">
                <thead> 
                    <tr>
                        <th *ngFor="let head of FieldAndHead?.head" [width]="WithCalculeSize">{{ head }}</th>      
                        <th>Actions</th>                   
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of ArrayDataAll">  
                        <td *ngFor="let key of validFields;">
                            {{ item[key] || 'S/I' }}
                        </td>
                        <td style="text-align: center;">
                            <div class="dropdown">
                                <i class="icon ion-more" data-toggle="dropdown" style="cursor: pointer;"></i>
                                <ul class="dropdown-menu">
                                    <div *ngFor="let option of options">
                                        <a *ngIf="option.icon.includes('trash')" (click)="toDelete(item)"   data-toggle="modal" data-target="#exampleModal" class="dropdown-item" style="cursor:pointer" >
                                            <i class={{option.icon}} style="margin-right: 10%;"></i>
                                            {{option.name}}
                                        </a>
                                        <a (click)="toOpenCreateElement(item, option.name)"  *ngIf="!option.icon.includes('trash') && renderOptSucursales" class="dropdown-item" style="cursor:pointer" >
                                            <i class={{option.icon}} style="margin-right: 10%;"></i>
                                            {{option.name}}
                                        </a>
                                        <a (click)="toOpenCreateElement(item, option.name)"  *ngIf="option.icon.includes('edit') && !renderOptSucursales" class="dropdown-item" style="cursor:pointer" >
                                            <i class={{option.icon}} style="margin-right: 10%;"></i>
                                            {{option.name}}
                                        </a>
                                    </div>
                                </ul>
                              </div>
                        </td>    

                    </tr>
                    <!-- <tr  *ngFor="let items of FieldAndHead?.data">
                        <td *ngFor="let item of items">{{ item }}</td>              
                              
                    </tr>                     -->
                    <tr *ngIf="loading">{{ loadingMessage }}</tr>
                </tbody>
            </table>
        </div>
        </div><!-- table-wrapper -->
       </div><!-- card-body -->
    </div><!-- card -->
</div><!-- sh-pagebody -->


<!-- Modal para Eliminar / confirma -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="position: absolute; z-index: 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            ¿Está seguro de querer eliminar {{ ElementName }}?
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="AceptarDelete()">Eliminar</button>
        </div>
        </div>
    </div>
</div>
  
 