import { Injectable, isDevMode } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { getMockUserPublicInfo } from 'src/mock-data/user';
import { IModule } from '../core/interfaces/IModule.interface';
import { IUser } from '../core/interfaces/IUser.interface';
import { AuthService } from '../core/services/auth.service';
import { DashboardModulesService } from '../core/services/dashboard-modules.service';
import { ModuleViewComponent } from '../pages/dashboard/components/module-view/module-view.component';
import { TableComponent} from 'src/app/shared/components/table/table.component';
import {NewUserSidebarComponent } from 'src/app/shared/components/new-user-sidebar/new-user-sidebar.component';
import { ModalGenericComponent } from 'src/app/shared/modal/modal-generic/modal-generic.component';
import { moduleViewResolver } from '../resolvers/module-view.resolver';
import { FieldDinamicComponent } from '../shared/components/field-dinamic/field-dinamic.component';
import { MasterCrudComponent } from '../shared/components/master-crud/master-crud.component';
import { PerfilComponent  } from '../pages/dashboard/layout/perfil/perfil.component';
import { PromotionsTagComponent } from '../pages/promotions-tag/promotions-tag.component';
import { CouponsTagComponent } from '../pages/coupons-tag/coupons-tag.component';
import { MasterLogsComponent } from '../pages/master-logs/master-logs.component';
import { ChartsComponent } from '../shared/components/charts/charts.component';
import { HomeComponent } from '../pages/home/home.component';
import { ZonesComponent } from '../pages/settings/zones/zones.component';
import { OdbsComponent } from '../pages/settings/odbs/odbs.component';
import { CapabilitiesComponent } from '../pages/settings/capabilities/capabilities.component';
import { OnusComponent } from '../pages/settings/onus/onus.component';
import { SpeedProfilesComponent } from '../pages/settings/speed-profiles/speed-profiles.component';
import { OltsComponent } from '../pages/settings/olts/olts.component';
import { OnuTypesComponent } from '../pages/settings/onu-types/onu-types.component';
import { VpnTunnelsComponent } from '../pages/settings/vpn-tunnels/vpn-tunnels.component';
import { UnconfiguredComponent } from '../pages/unconfigured/unconfigured.component';
import { ConfiguredComponent } from '../pages/configured/configured.component';
import { GraphsComponent } from '../pages/graphs/graphs.component';
import { DiagnosticsComponent } from '../pages/diagnostics/diagnostics.component';
import { AuthorizationComponent } from '../pages/reports/authorization/authorization.component';
import { ExportsComponent } from '../pages/reports/exports/exports.component';
import { ImportsComponent } from '../pages/reports/imports/imports.component';
import { ViewsComponent } from '../pages/configured/views/views.component';
import { ViewComponent } from '../pages/settings/olts/view/view-olt.component';
import { CreateComponent } from '../pages/settings/olts/create/create.component';
import { AddOnuComponent } from '../pages/settings/onus/add-onu/add-onu.component';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  //Esto es temporal
  private Menus =['Consulta', 'Nueva']

  constructor(
    private router: Router,
    private authService: AuthService,
    private dashboardModulesService: DashboardModulesService
  ) { }

  /**
   * Sets the values for every service that involves user data.
   * @returns
   */
  public async loadConfigurationData(): Promise<void> {
    // console.log('metodo de rutas')
    let user: IUser;

    // attempt session retrieval
    try {
      if (isDevMode()) {
        // in dev mode the user will always exist, not convenient if you want to test user authentication
        let res = await getMockUserPublicInfo();
        // console.log(res)
        user = res.data;
      } else {

        // let res = await getMockUserPublicInfo();
        // // console.log(res)
        // user = res.data;
        
        user = await this.authService.getUserSession();
        // console.log('USER SESSION')
        // console.log(user)
      }
    }
    catch(error: any) {
      /**
       * status === 0 is basically that the backend doesn't even exist, no response. It's something expected if the server is still not setup anywhere,
       * either locally or on the web
       */
      if (error.request.status === 0 && isDevMode()) return;

      if(error.request.status === 401) {
        // if user session is no longer active, redirect them to login
        this.router.navigate(['/signin']);
      } else if ([0, 403, 500, 503].includes(error.request.status)) {
        // if error is something fatal, show error page
        const status_code = error.request.status === 0 ? 503 : error.request.status; // status_code 0 is a special case (service isn't even running)
        this.router.navigate(['/error'], { queryParams : { status_code }});
      }
      return;
    }

    // user could retrieve session safely
    // console.log('user session resumed');

    /*------------ initialize service's values -------------*/
    // take the user object and split data into their services
    if(user.modules.length > 0) {
      this.dashboardModulesService.init(user.modules);
      try {
        this.loadAditionalRoutes(user.modules, 'dashboard'); // CAUTION, this function CAN throw an error, so handle appropiately
        //console.log(user.modules)
      } catch (error) {
        console.error(error);
      }
    } else {
      // console.log('user has no modules');
    }
    /*-------------------------------------------------------*/
  }

  getRoutesDataUser( ahsdhj: any ) {
    this.getNewRoutes(ahsdhj)
  }

  /**
   * Recursive function that returns routes generated from an API for a certain user.
   * @param userModules
   * @returns the returned routes are defined as { path: module.route, component: ModuleViewComponent, title: `Fibex OLT - ${module.name}`, data: { module } }.
   * The "data" property passes on the module object that should be used to perform operations, like fetch the data for that module, etc.
   */
  private getNewRoutes(userModules: IModule[]): Routes {

    let userModulesRoutes: Routes = [];

    for (let module of userModules) {
      // virtually this shouldn't occur, but just in case (a module that isn't a dropdown or isn't a standalone module would be nothing more than a decoration)
      if (!module.route && module.childrenModules.length === 0) continue;

        switch (module.route) {
          case "home":
            userModulesRoutes.push({
              path: module.route,
              component: HomeComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "configuracion/Usuario":
            userModulesRoutes.push({
              path: module.route,
              component: NewUserSidebarComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
            break;

          case "logs/consulta":

            userModulesRoutes.push({
              path: module.route,
              component: MasterLogsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "unconfigured":

            userModulesRoutes.push({
              path: module.route,
              component: UnconfiguredComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "configured":

            userModulesRoutes.push({
              path: module.route,
              component: ConfiguredComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "configured/views":

            userModulesRoutes.push({
              path: module.route,
              component: ViewsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "graphs":

            userModulesRoutes.push({
              path: module.route,
              component: GraphsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "diagnostics":

            userModulesRoutes.push({
              path: module.route,
              component: DiagnosticsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "reports/authorization":

            userModulesRoutes.push({
              path: module.route,
              component: AuthorizationComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "reports/exports":

            userModulesRoutes.push({
              path: module.route,
              component: ExportsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "reports/imports":

            userModulesRoutes.push({
              path: module.route,
              component: ImportsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
            });
          break;

          case "settings/zones":

            userModulesRoutes.push({
              path: module.route,
              component: ZonesComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/capability":

            userModulesRoutes.push({
              path: module.route,
              component: CapabilitiesComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/odbs":

            userModulesRoutes.push({
              path: module.route,
              component: OdbsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/onus":

            userModulesRoutes.push({
              path: module.route,
              component: OnusComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/onus/addNewOnu":

          userModulesRoutes.push({
            path: module.route,
            component: AddOnuComponent,
            title: `Fibex OLT - ${module.name}`,
            data: { module },
          });
        break;

          case "settings/speed_profiles":

            userModulesRoutes.push({
              path: module.route,
              component: SpeedProfilesComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/olts":

            userModulesRoutes.push({
              path: module.route,
              component: OltsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/olts/create":

            userModulesRoutes.push({
              path: module.route,
              component: CreateComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/olts/view":

            userModulesRoutes.push({
              path: module.route,
              component: ViewComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/onuTypes":

            userModulesRoutes.push({
              path: module.route,
              component: OnuTypesComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          case "settings/vpn-tunnels":

            userModulesRoutes.push({
              path: module.route,
              component: VpnTunnelsComponent,
              title: `Fibex OLT - ${module.name}`,
              data: { module },
            });
          break;

          default:
            if (module.route) {
              userModulesRoutes.push({
                path: module.route,
                component: ModuleViewComponent,
                title: `Fibex OLT - ${module.name}`,
                data: { module }, // pass the module (holds info about how to retrieve module data and what children or parent modules they have)
                resolve: { // resolver used to fetch data before switching routes
                  contentBlocks: moduleViewResolver
                }
              });
            }
        }





      // if it has children modules, add the children routes
     if (module.childrenModules.length > 0) {
        userModulesRoutes = userModulesRoutes.concat(this.getNewRoutes(module.childrenModules));
      }
    }
    // console.log(userModulesRoutes)

    return userModulesRoutes;
  }

  /**
   * Create and load routes defined from modules that come from the backend
   * @param userModules
   * @param basePath the base path where the children routes should be defined
   */
  private loadAditionalRoutes(userModules: IModule[], basePath?: string): void | never {
    const dashboardIndex = this.router.config.findIndex((route) => route.path === basePath);
    if (dashboardIndex === -1) throw new Error(`Could not find a base path with the value of '${basePath}'`);
    const userModulesRoutes = this.getNewRoutes(userModules);
    // console.log(userModulesRoutes)
    this.router.config[dashboardIndex].children?.unshift(...userModulesRoutes);
  }
}
