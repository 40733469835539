import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ConfigurationService } from './services/configuration.service';
import { dbFullService } from './services/dbFull.service';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { HttpErrorComponent } from './pages/http-error/http-error.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { CoreSidebarModule } from 'src/@core/components/core-sidebar/core-sidebar.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilComponent } from './pages/dashboard/layout/perfil/perfil.component';
import { MasterLogsComponent } from './pages/master-logs/master-logs.component';
import { ChartsComponent } from './shared/components/charts/charts.component';
import { HomeComponent } from './pages/home/home.component';
import { ZonesComponent } from './pages/settings/zones/zones.component';
import { OdbsComponent } from './pages/settings/odbs/odbs.component';
import { CapabilitiesComponent } from './pages/settings/capabilities/capabilities.component';
import { OnusComponent } from './pages/settings/onus/onus.component';
import { SpeedProfilesComponent } from './pages/settings/speed-profiles/speed-profiles.component';
import { OltsComponent } from './pages/settings/olts/olts.component';
import { OnuTypesComponent } from './pages/settings/onu-types/onu-types.component';
import { VpnTunnelsComponent } from './pages/settings/vpn-tunnels/vpn-tunnels.component';
import { UnconfiguredComponent } from './pages/unconfigured/unconfigured.component';
import { ConfiguredComponent } from './pages/configured/configured.component';
import { GraphsComponent } from './pages/graphs/graphs.component';
import { DiagnosticsComponent } from './pages/diagnostics/diagnostics.component';
import { AuthorizationComponent } from './pages/reports/authorization/authorization.component';
import { ExportsComponent } from './pages/reports/exports/exports.component';
import { ImportsComponent } from './pages/reports/imports/imports.component';
import { PromotionsTagComponent } from './pages/promotions-tag/promotions-tag.component';
import { CouponsTagComponent } from './pages/coupons-tag/coupons-tag.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ViewsComponent } from './pages/configured/views/views.component';
import { CreateComponent } from './pages/settings/olts/create/create.component';
import { AddOnuComponent } from './pages/settings/onus/add-onu/add-onu.component';
import { ViewComponent } from './pages/settings/olts/view/view-olt.component';
import { ModalComponent } from './shared/components/modal-component/modal-component.component';
import { ListaComponent } from './shared/components/lista/lista.component';
import { FilterComponent } from './filter/filter.component';
import { ListaViewTabComponent } from './shared/components/lista-view-tab/lista-view-tab.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export function initializeApp(configurationService: ConfigurationService) {
  return () => configurationService.loadConfigurationData();
}

@NgModule({
  declarations: [
    AppComponent,
    // route views (root pages)
    PerfilComponent,
    SigninComponent,
    SignupComponent,
    HttpErrorComponent,
    MasterLogsComponent,
    ChartsComponent,
    HomeComponent,
    ZonesComponent,
    CapabilitiesComponent,
    OdbsComponent,
    OnusComponent,
    SpeedProfilesComponent,
    OltsComponent,
    OnuTypesComponent,
    VpnTunnelsComponent,
    UnconfiguredComponent,
    ConfiguredComponent,
    ViewsComponent,
    ViewComponent,
    GraphsComponent,
    DiagnosticsComponent,
    AuthorizationComponent,
    ExportsComponent,
    ImportsComponent,
    PromotionsTagComponent,
    CouponsTagComponent,
    FilterPipe,
    ViewsComponent,
    CreateComponent,
    AddOnuComponent,
    ModalComponent,
    ListaComponent,
    FilterComponent,
    ListaViewTabComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CoreModule, // shared services that live across components
    AppRoutingModule,
    DashboardModule,
    CoreSidebarModule,
    SharedModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule
    
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigurationService],
      multi: true
    },
    dbFullService,
    FilterPipe  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

