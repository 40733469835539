<div class="container">
  <div class="title">
    <a [routerLink]="['/dashboard/configured']"
      ><i
        class="fa fa-reply"
        style="font-size: 36px; color: #000; padding-right: 50px"
      ></i
    ></a>
    <h1>View ONU</h1>
  </div>
  <div class="row p-2">
    <div *ngIf="loading; else contentList" class="div-centrado">
      <app-loader></app-loader>
    </div>
    <ng-template #contentList>
      <div class="col-xs-12 col-sm-6">
        <ul class="mt-2">
          <li>
            <strong>OLT: </strong
            ><a href="#moveOnu" data-toggle="modal">{{ dataOnu.olt_name}}</a>
          </li>
          <li>
            <strong>Board: </strong
            ><a href="#movePortOnu" data-toggle="modal">{{ dataOnu.board }}</a>
          </li>
          <li>
            <strong>Port: </strong
            ><a href="#movePortOnu" data-toggle="modal">{{ dataOnu.port }}</a>
          </li>
          <li><strong>ONU: </strong> {{ dataOnu.pon_type | lowercase }}-onu_0/{{ dataOnu.board }}/{{ dataOnu.port }} </li>
          <li>
            <strong>SN: </strong
            ><a href="#replaceOnu" data-toggle="modal">{{ dataOnu.sn }}</a>
          </li>
          <li>
            <strong>Zone: </strong
            ><a href="#updateLocation" data-toggle="modal">{{
              dataOnu.zone_name
            }}</a>
          </li>
          <li>
            <strong>ODB: </strong
            ><a href="#updateLocation" data-toggle="modal">{{
              dataOnu.odb
            }}</a>
          </li>
          <li>
            <strong>Name: </strong
            ><a href="#updateLocation" data-toggle="modal">{{ dataOnu.onu_type_id }}</a>
          </li>
          <li>
            <strong>Address or comment: </strong
            ><a href="#updateLocation" data-toggle="modal">
              {{ dataOnu.address }}</a
            >
          </li>
          <li><strong>Contact:</strong></li>
          <li>
            <strong>Authorization date: </strong>{{ dataOnu.authorization_date
            }}<a href="#history" data-toggle="modal"> History</a>
          </li>
          <li>
            <strong>ONU external ID: </strong
            ><a href="#updateId" data-toggle="modal">{{
              dataOnu.onu_external
            }}</a>
          </li>
        </ul>
      </div>

      <div class="col-xs-12 col-sm-6">
        <ul class="mt-2">
          <img
            class="img-responsive img-rounded mb-4"
            src="https://fibextelecom.smartolt.com/content/img/4_eth_1_voip_0_catv.png"
          />
          <li>
            <strong>Status:</strong>
            {{ dataOnu.status }}
            <i
              class="{{
                dataOnu.status == 'Offline'
                  ? 'fa fa-globe fa-md text-danger'
                  : 'fa fa-globe fa-md text-success'
              }}"
            ></i>
          </li>
          <li>
            <strong>ONU/OLT Rx signal:</strong> {{ dataOnu.signal_1310 }}
            <i [class]="switchSignal(dataOnu.signal)"></i>
          </li>
          <li>
            <strong>Attached VLANs: </strong
            ><a href="#updateVlan" data-toggle="modal"> {{ dataOnu.vlan }} </a>
          </li>
          <li>
            <strong>ONU mode: </strong
            ><a href="#updateMode" data-toggle="modal"
              >{{ dataOnu.mode }} - vlan: {{ dataOnu.vlan }}</a
            >
          </li>
          <li><strong>TR069:</strong> None</li>
          <li><strong>Mgmt IP:</strong> None</li>
          <li><strong>WAN setup mode:</strong> None</li>
        </ul>
      </div>

      <div class="buttons mt-4 mb-4">

        <button
          type="button"
          class="btn btn-primary mr-2"
          [disabled]="loadingStatus"
          (click)="getStatus()"

        >
          Get Status <i *ngIf="loadingStatus" class="fa fa-spinner fa-spin margin-left"></i>
        </button>

        <button type="button" class="btn btn-primary mr-2" disabled="disabled">
          Show running config
        </button>
        <button type="button" class="btn btn-primary mr-2" disabled="disabled">
          SW info
        </button>
        <button type="button" class="btn btn-success" disabled="disabled">
          LIVE!
        </button>
      </div>

      <div class="col-md-12 status" *ngIf="status && !loadingStatus">

        <div class="closeStatus">
          <i (click)="status = null" class="fa fa-times cursor-pointer" aria-hidden="true"></i>
        </div>

        <h5>Status</h5>

        <div class="col-mod-6" *ngFor="let key of getObjKeys(status)">
          <p><strong>{{key.includes("_") ? key.split("_").join(" ").toUpperCase() : key.toUpperCase() }}</strong></p>
          
          <ul *ngIf="isObject(status[key])">

            <li *ngFor="let subKey of getObjKeys(status[key])">
    
              <b>{{ subKey.includes("_") ? subKey.split("_").join(" ").toUpperCase() : subKey.toUpperCase() }}</b>: {{ status[key][subKey] }}
            </li>
            
          </ul>
          

        </div>

        <!-- <pre>{{ status }}</pre> -->
      </div>

      <div class="table mt-4">
        <h2>Traffic/Signal</h2>
        <div class="imag mt-4">
          <div class="co">
            <app-chart-multiple
              idChart="FirstID"
              title="Traffic"
              subtitle="Upload"
              subtitle2="Download"
              type="line"
              [data]="data"
              [labels]="labels"
              color="#f40418"
              [data2]="data3"
              color2="#1091fe"
              (click)="mostrar(true, 'Traffic')"
              data-toggle="modal"
              data-target="#exampleModal"
            >
            </app-chart-multiple>
          </div>
          <div class="co">
            <app-chart
              idChart="SecondID"
              title="Signal"
              subtitle="Signal"
              type="line"
              [data]="data2"
              [labels]="labels"
              title2="Signal"
              subtitle2="Signal"
              color="#1bd10b"
              (click)="mostrar(true, 'Signal')"
              data-toggle="modal"
              data-target="#exampleModal2"
            >
            </app-chart>
          </div>
        </div>
      </div>

       <!--Modal-->
      <div
        *ngIf="show"
        class="modal fade bd-example-modal-lg"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-large modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title title" id="exampleModalLabel">
                {{ modalTitle }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <app-chart-multiple
                    idChart="myChart"
                    title="Hour"
                    subtitle="Upload"
                    subtitle2="Download"
                    type="line"
                    [data]="data"
                    [labels]="labels"
                    color="#f40418"
                    [data2]="data3"
                    color2="#1091fe"
                  >
                  </app-chart-multiple>
                </div>
                <div class="col-md-6">
                  <app-chart-multiple
                    idChart="myChart2"
                    title="Daily"
                    subtitle="Upload"
                    subtitle2="Download"
                    type="line"
                    [data]="data"
                    [labels]="labels"
                    color="#f40418"
                    [data2]="data3"
                    color2="#1091fe"
                  >
                  </app-chart-multiple>
                </div>
                <div class="col-md-6">
                  <app-chart-multiple
                    idChart="myChart3"
                    title="Weekly"
                    subtitle="Upload"
                    subtitle2="Download"
                    type="line"
                    [data]="data"
                    [labels]="labels"
                    color="#f40418"
                    [data2]="data3"
                    color2="#1091fe"
                  >
                  </app-chart-multiple>
                </div>
                <div class="col-md-6">
                  <app-chart-multiple
                    idChart="myChart4"
                    title="Monthly"
                    subtitle="Upload"
                    subtitle2="Download"
                    type="line"
                    [data]="data"
                    [labels]="labels"
                    color="#f40418"
                    [data2]="data3"
                    color2="#1091fe"
                  >
                  </app-chart-multiple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <!--Modal-->
      <div
        *ngIf="show"
        class="modal fade bd-example-modal-lg"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-large modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title title" id="exampleModalLabel">
                {{ modalTitle }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <app-chart
                    idChart="myChart5"
                    title="Hour"
                    subtitle="signal"
                    type="line"
                    [data]="data2"
                    [labels]="labels"
                    title2="Signal"
                    subtitle2="signal"
                    color="#1bd10b"
                  >
                  </app-chart>
                </div>
                <div class="col-md-6">
                  <app-chart
                    idChart="myChart6"
                    title="Daily"
                    subtitle="signal"
                    type="line"
                    [data]="data2"
                    [labels]="labels"
                    title2="Signal"
                    subtitle2="signal"
                    color="#1bd10b"
                  >
                  </app-chart>
                </div>
                <div class="col-md-6">
                  <app-chart
                    idChart="myChart7"
                    title="Weekly"
                    subtitle="signal"
                    type="line"
                    [data]="data2"
                    [labels]="labels"
                    title2="Signal"
                    subtitle2="signal"
                    color="#1bd10b"
                  >
                  </app-chart>
                </div>
                <div class="col-md-6">
                  <app-chart
                    idChart="myChart8"
                    title="Monthly"
                    subtitle="signal"
                    type="line"
                    [data]="data2"
                    [labels]="labels"
                    title2="Signal"
                    subtitle2="signal"
                    color="#1bd10b"
                  >
                  </app-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table mt-4">
        <h3>Speed profiles</h3>
        <table
          class="table table-bordered table-striped table-condensed table-nonfluid mt-4"
        >
          <tbody>
            <tr>
              <th>Service-port ID</th>
              <th>SVLAN</th>
              <th>User-VLAN</th>
              <th>Download</th>
              <th>Upload</th>
              <th>Action</th>
            </tr>
          </tbody>
          <tbody *ngFor="let service_port of dataOnu.service_ports">
            <tr class="valign-center">
              <td>{{ service_port.service_port }}</td>
              <td>{{ service_port.svlan }}</td>
              <td>{{ service_port.cvlan }}</td>
              <td>{{ service_port.download_speed }}</td>
              <td>{{ service_port.upload_speed }}</td>
              <td>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#configureModalSpeed"
                  class="btn btn-link configure-vlan"
                >
                  <i class="fa fa-plus-circle"></i> Configure</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table mt-4">
        <h3>Ethernet ports</h3>
        <table
          class="table table-bordered table-striped table-condensed table-nonfluid mt-4"
        >
          <tbody>
            <tr>
              <th class="col-md-1">Port</th>
              <th class="col-md-1">Admin state</th>
              <th class="col-md-3">Mode</th>
              <th class="col-md-1">DHCP</th>
              <th class="col-md-1 text-center">Action</th>
            </tr>
          </tbody>
          <tbody *ngFor="let ethernet_port of dataOnu.ethernet_ports">
            <tr class="valign-center">
              <td>{{ ethernet_port.port }}</td>
              <td>{{ ethernet_port.admin_state }}</td>
              <td>{{ ethernet_port.mode }}</td>
              <td>{{ ethernet_port.dhcp }}</td>
              <td>
                <a
                  href="#configureNetworkPort"
                  data-toggle="modal"
                  data-target="#configureModalPort"
                  class="btn btn-link configure-vlan"
                >
                  <i class="fa fa-plus-circle"></i> Configure
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="catv">
        <p><strong>VoIP service:</strong></p>
        <p><strong>CATV:</strong> {{ dataOnu.catv }}</p>
        <div class="buttons my-4">
          <button
            type="button"
            class="btn btn-primary mr-2"
            style="background-color: #f39c12; border-color: #f39c12"
            disabled="disabled"
          >
            Reboot
          </button>
          <button type="button" class="btn btn-warning mr-2" disabled="disabled">
            Resync config
          </button>
          <button type="button" class="btn btn-warning mr-2" disabled="disabled">
            Restore defaults
          </button>
          <button type="button" class="btn btn-warning mr-2" disabled="disabled">
            Disable ONU
          </button>
          <button type="button" class="btn btn-danger" disabled="disabled">Delete</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="configureModalPort"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Configure ethernet port
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="">
          <div class="form-group">
            <label for="" class="control-label col-sm-3"
              ><strong>Status</strong></label
            >
            <div class="col-sm-7">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="enabled"
                  name="status"
                  value="Enabled"
                  [(ngModel)]="ethernetPorts.status"
                />
                <label for="enabled" class="form-check-label">Enabled</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="shutdown"
                  name="status"
                  value="Port shutdown"
                  [(ngModel)]="ethernetPorts.status"
                />
                <label for="shutdown" class="form-check-label"
                  >Port shutdown</label
                >
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="" class="control-label col-sm-3"
              ><strong>Mode</strong></label
            >
            <div class="col-sm-12">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="mode"
                  id="lan"
                  value="LAN"
                  [(ngModel)]="ethernetPorts.mode"
                />
                <label for="lan" class="form-check-label">LAN</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="mode"
                  id="access"
                  value="Access"
                  [(ngModel)]="ethernetPorts.mode"
                />
                <label for="access" class="form-check-label">Access</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="mode"
                  id="hybrid"
                  value="Hybrid"
                  [(ngModel)]="ethernetPorts.mode"
                />
                <label for="hybrid" class="form-check-label">Hybrid</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="mode"
                  id="trunk"
                  value="Trunk"
                  [(ngModel)]="ethernetPorts.mode"
                />
                <label for="trunk" class="form-check-label">Trunk</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="mode"
                  id="trans"
                  value="Trans"
                  [(ngModel)]="ethernetPorts.mode"
                />
                <label for="trans" class="form-check-label">Trans</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="" class="control-label col-sm-3"
              ><strong>DHCP</strong></label
            >
            <div class="col-sm-12">
              <P>No control</P>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success" (click)="submit(2)">
          Aceptar
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="moveOnu"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"

  *ngIf="dataOnu"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Move ONU</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          class="move-onu-form form-horizontal"
          method="post"
          accept-charset="utf-8"
        >
          <div class="modal-body">
            <div class="form-group" id="changeOltWrapper">
              <label class="control-label col-sm-2" for="change_olt_id"
                >OLT</label
              >
              <div class="col-md-10">
                <select
                  name="change_olt_id"
                  id="change_olt_id"
                  class="form-control"
                >
                <option>
                    {{ dataOnu.olt_name}}
                  </option>
                  <option
                    value="{{ data.id }}"
                    *ngFor="let data of selectedOlt"
                  >
                    {{ data.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="control-label col-sm-2" for="change_board"
                >Board</label
              >
              <div class="col-md-10">
                <select
                  name="change_board"
                  id="change_board"
                  class="form-control"
                  [value]="dataOnu.board"
                  [(ngModel)]="dataOnu.board"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="control-label col-sm-2" for="change_port"
                >Port</label
              >
              <div class="col-md-10">
                <select
                  name="change_port"
                  id="change_port"
                  class="form-control"
                  [value]="dataOnu.port"
                  [(ngModel)]="dataOnu.port"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </select>
              </div>
            </div>

            <div
              class="form-group"
              id="moveToDefaultVlanWrapper"
              style="display: none"
            >
              <label class="control-label col-sm-2"></label>
              <div class="col-md-10">
                <label
                  >The default VLAN-ID <span class="default-vlan"></span> for
                  this PON port is different from the ONU main VLAN-ID
                  <span class="main-vlan">100</span>. Do you want to change it
                  to the default VLAN-ID
                  <span class="default-vlan"></span>?</label
                >
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="move_to_default_vlan"
                      value="1"
                      id="move_to_default_vlan"
                    />
                    Change main VLAN-ID to default VLAN-ID
                    <span class="default-vlan"></span>
                  </label>
                </div>
              </div>
            </div>

            <input type="hidden" id="mainVlan" class="main-vlan" />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-success">Accept</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="movePortOnu"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"

  *ngIf="dataOnu"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Move ONU</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          class="move-onu-form form-horizontal"
          method="post"
          accept-charset="utf-8"
        >
          <div class="modal-body">
            <div class="form-group">
              <label class="control-label col-sm-2" for="change_board"
                >Board</label
              >
              <div class="col-md-10 mx-4">
                <select
                  name="change_board"
                  id="change_board"
                  class="form-control"
                  [value]="dataOnu.board"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="control-label col-sm-2" for="change_port"
                >Port</label
              >
              <div class="col-md-10 mx-4">
                <select
                  name="change_port"
                  id="change_port"
                  class="form-control"
                  [value]="dataOnu.port"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </select>
              </div>
            </div>

            <div
              class="form-group"
              id="moveToDefaultVlanWrapper"
              style="display: none"
            >
              <label class="control-label col-sm-2"></label>
              <div class="col-md-10">
                <label
                  >The default VLAN-ID <span class="default-vlan"></span> for
                  this PON port is different from the ONU main VLAN-ID
                  <span class="main-vlan">100</span>. Do you want to change it
                  to the default VLAN-ID
                  <span class="default-vlan"></span>?</label
                >
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="move_to_default_vlan"
                      value="1"
                      id="move_to_default_vlan"
                    />
                    Change main VLAN-ID to default VLAN-ID
                    <span class="default-vlan"></span>
                  </label>
                </div>
              </div>
            </div>

            <input type="hidden" id="mainVlan" class="main-vlan" value="100" />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-success">Accept</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="replaceOnu"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  *ngIf="dataOnu"

>
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Replace ONU by SN
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          action=""
          class="update-sn-form form-horizontal"
          method="post"
          accept-charset="utf-8"
        >
          <div class="form-group">
            <label class="control-label col-sm-3" for="sn">SN</label>
            <div class="col-md-8">
              <div class="search">
                <input
                  type="text"
                  id="sn"
                  name="sn"
                  class="form-control"
                  [(ngModel)]="dataOnu.sn"
                />
                <div
                  class="padding-left margin-top"
                  style="margin-left: 10px; margin-top: 5px"
                >
                  <a
                    href="#"
                    class="lookupautofind"
                    data-type="gpon"
                    title="Lookup for ONUs waiting authorization"
                    ><i class="fa fa-search" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
              <span class="help-block" id="lookupautofind_hint"
                >Use the looking glass to search in the unconfigured list for
                this PON</span
              >
            </div>
          </div>

          <div class="form-group" style="display: none">
            <label class="control-label col-sm-3" for="snautofind"
              >Found SNs</label
            >
            <div class="col-md-8">
              <select id="snautofind" class="form-control"></select>
            </div>
          </div>

          <div class="form-group">
            <label class="control-label col-sm-3" for="new_sn_onu_type_id"
              >ONU type</label
            >
            <div class="col-md-8">
              <select
                name="new_sn_onu_type_id"
                id="new_sn_onu_type_id"
                class="form-control"
              >
              <option>
                {{ dataOnu.onu_type }}
              </option>
                <option value="{{ data.id }}" *ngFor="let data of onuTypes">
                  {{ data.name }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="updateLocation"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  *ngIf="dataOnu"

>
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Update location details
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          action=""
          class="update-location-details-form form-horizontal"
          method="post"
          accept-charset="utf-8"
        >
          <div class="form-group">
            <label class="control-label col-sm-3" for="zone_id">Zone</label>
            <div class="col-sm-9">
              <span class="multiselect-native-select"
                ><select name="zone_id" id="zone_id" class="form-control">
                  <option value="33">1</option>
                  <option value="34">33</option>
                  <option value="35">Zona 3</option>
                  <option value="36">Zona 4</option>
                  <option value="37">Zona 5</option>
                  <option value="1">Zone 1</option>
                  <option value="7">Zone 2</option>
                </select>
              </span>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-3" for="odb_id"
              >ODB (Splitter)</label
            >
            <div class="col-sm-9">
              <span class="multiselect-native-select">
                <select name="odb_id" id="odb_id" class="form-control">
                  <option value="0">None</option>
                  <option value="20">Z</option>
                  <option value="12">Zon</option>
                  <option value="7">Zone</option>
                  <option value="5">Zone 1</option>
                  <option value="21">Zone 1 a</option>
                  <option value="18">Zone 1 au</option>
                  <option value="19">Zone 1 aut</option>
                </select>
              </span>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-3" for="odb_port"
              >ODB port</label
            >
            <div class="col-sm-9">
              <span class="multiselect-native-select"
                ><select name="odb_port" id="odb_port" class="form-control">
                  <option value="0">None</option>
                </select>
              </span>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-3" for="location_name"
              >Name</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                id="location_name"
                name="location_name"
                class="form-control"
                [(ngModel)]="dataOnu.onu"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-3" for="description"
              >Address or comment</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                id="description"
                name="description"
                class="form-control"
                [(ngModel)]="dataOnu.address"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-3" for="contact">Contact</label>
            <div class="col-sm-9">
              <input
                type="text"
                id="contact"
                name="contact"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-3" for="latitude"
              >Latitude</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                id="latitude"
                name="latitude"
                class="form-control"
                pattern="^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$"
                data-invalidmsg=""
              />
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-3" for="longitude"
              >Longitude</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                id="longitude"
                name="longitude"
                class="form-control"
                pattern="^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$"
                data-invalidmsg=""
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="history"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  *ngIf="dataOnu"

>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">History</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="history-modal-body margin-10">
          <table class="table table-striped table-fixed-ellipsis">
            <tbody>
              <tr>
                <th class="col-md-2">Action</th>
                <th class="col-md-1">User</th>
                <th class="col-md-1">Date</th>
              </tr>

              <tr class="valign-center">
                <td>ONU: {{ dataOnu.sn }} Authorized</td>
                <td class="user" title="API">API</td>
                <td>{{ dataOnu.authorization_date }}</td>
              </tr>
            </tbody>
          </table>
          <a href="" class="btn btn-default btn-block">View all history</a>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="updateId"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  *ngIf="dataOnu"

>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Update ONU external ID
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          action=""
          class="update-client-external-id-form form-horizontal"
          method="post"
          accept-charset="utf-8"
        >
          <div class="form-group">
            <label class="control-label col-sm-4" for="client_external_id"
              >ONU external ID</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                id="client_external_id"
                name="client_external_id"
                class="form-control"
                [(ngModel)]="dataOnu.unique_external_id"
              />
            </div>
          </div>
          <span class="help-block"
            >Use the unique ONU external ID with API or billing systems.</span
          >
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="updateVlan"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  *ngIf="dataOnu"

>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Update VLANs</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          action=""
          class="update-extra-vlans-form form-horizontal"
          method="post"
          accept-charset="utf-8"
        >
          <div class="form-group">
            <label class="control-label col-sm-2" for="extra_vlans"
              >VLANs</label
            >
            <div class="col-sm-8">
              <span class="multiselect-native-select">
                <select
                  id="extra_vlans"
                  name="extra_vlans[]"
                  class="form-control"
                >
                  <option value="1969">4</option>
                  <option value="1970">100</option>
                  <option value="2369">267</option>
                  <option value="1971">300</option>
                  <option value="1968">932</option>
                  <option value="2472">957</option>
                  <option value="2462">958</option>
                  <option value="1999">962</option>
                  <option value="2393">964</option>
                  <option value="1972">966 - FTTH RESIDENCIAL</option>
                  <option value="1973">967 - FTTH RESIDENCIAL</option>
                  <option value="1974">968 - FTTH RESIDENCIAL</option>
                  <option value="1975">969 - FTTH PYMES</option>
                  <option value="2163">970</option>
                  <option value="2008">973</option>
                  <option value="2348">974</option>
                  <option value="2058">980</option>
                  <option value="2391">984</option>
                  <option value="2142">988</option>
                  <option value="2088">993 - HOSTPOT-EXONERAD</option>
                  <option value="2189">998 - DEDICADOS</option>
                </select>
              </span>
            </div>
          </div>
          <span class="help-block"
            >Select from the list all the VLANs that will be used on this
            ONU.</span
          >
          <span class="help-block"
            >After changing the VLANs list, go to the Ethernet ports settings
            and assign VLANs as desired.</span
          >
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="updateMode"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  *ngIf="dataOnu"

>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Update ONU mode</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="control-label col-sm-3" for="default_vlan_id"
            ><span class="routerModeItemModal">WAN</span> VLAN-ID</label
          >
          <div class="col-sm-8">
            <select
              name="default_vlan_id"
              id="default_vlan_id"
              class="form-control"
            >
              <optgroup label="Attached VLANs">
                <option value="1970">100</option>
              </optgroup>
              <optgroup label="Other VLANs">
                <option value="1969">4</option>
                <option value="2369">267</option>
                <option value="1971">300</option>
                <option value="1968">932</option>
                <option value="2472">957</option>
                <option value="2462">958</option>
                <option value="1999">962</option>
                <option value="2393">964</option>
                <option value="1972">966 - FTTH RESIDENCIAL</option>
                <option value="1973">967 - FTTH RESIDENCIAL</option>
                <option value="1974">968 - FTTH RESIDENCIAL</option>
                <option value="1975">969 - FTTH PYMES</option>
                <option value="2163">970</option>
                <option value="2008">973</option>
                <option value="2348">974</option>
                <option value="2058">980</option>
                <option value="2391">984</option>
                <option value="2142">988</option>
                <option value="2088">993 - HOSTPOT-EXONERAD</option>
                <option value="2189">998 - DEDICADOS</option>
              </optgroup>
            </select>
          </div>
        </div>

        <span class="help-block"
          >After changing the
          <span class="routerModeItemModal">WAN</span> VLAN-ID, please check the
          Ethernet ports settings and update VLANs as desired.</span
        >

        <div class="form-group" id="mode_wrapper">
          <label class="control-label col-sm-3 radio-inline" for="mode"
            >ONU mode</label
          >
          <div class="col-sm-8">
            <label class="radio-inline"
              ><input
                type="radio"
                id="router"
                name="mode"
                value="Routing"
                style="margin-right: 1rem; margin-left: 1rem;"
              />Routing</label
            >
            <label class="radio-inline"
              ><input
                type="radio"
                id="bridge"
                name="mode"
                value="Bridging"
                style="margin-right: 1rem; margin-left: 1rem;"
              />Bridging</label
            >
          </div>
        </div>
        <div class="form-group routerModeItemModal" id="router_mode_wrapper">
          <label class="control-label col-sm-3 radio-inline" for="router_mode"
            >WAN mode</label
          >
          <div class="col-sm-8">
            <label class="radio radio-single"
              ><input
                type="radio"
                id="noConf"
                name="router_mode"
                value="Setup via ONU webpage"
                checked="checked"
                style="margin-right: 1rem; margin-left: 1rem;"
              />Setup via ONU webpage</label
            >
            <label class="radio radio-single only-label"
            style="margin-left: 1rem;"
              >Settings for compatible ONUs:</label
            >
            <label class="radio-inline"
              ><input
                type="radio"
                id="dhcp"
                name="router_mode"
                value="DHCP"
                style="margin-right: 1rem; margin-left: 1rem;"
              />DHCP</label
            >
            <label class="radio-inline"
              ><input
                type="radio"
                id="static"
                name="router_mode"
                value="Static"
                style="margin-right: 1rem; margin-left: 1rem;"
              />Static IP</label
            >
            <label class="radio-inline"
              ><input
                type="radio"
                id="pppoe"
                name="router_mode"
                value="PPPoE"
                style="margin-right: 1rem; margin-left: 1rem;"
              />PPPoE</label
            >
          </div>
        </div>

        <div
          class="form-group routerModeItemModal ipProtocolModal"
          style="display: none"
        >
          <label
            class="control-label col-sm-3 radio-inline"
            for="configuration_method"
            >Config method</label
          >
          <div class="col-sm-8">
            <label class="radio-inline"
              ><input
                type="radio"
                id="OMCI"
                name="configuration_method"
                value="OMCI"
                disabled=""
              />OMCI</label
            >
            <label class="radio-inline"
              ><input
                type="radio"
                id="TR069"
                name="configuration_method"
                value="TR069"
                disabled=""
              />TR069 - inactive</label
            >
          </div>
        </div>

        <div
          class="form-group routerModeItemModal staticItemModal"
          style="display: none"
        >
          <label class="control-label col-sm-3" for="ipv4_address"
            >IPv4 address</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              id="ip_address"
              name="ip_address"
              class="form-control"
              pattern="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              data-invalidmsg="Please input a valid value for the IPv4 address"
            />
          </div>
        </div>
        <div
          class="form-group routerModeItemModal staticItemModal"
          style="display: none"
        >
          <label class="control-label col-sm-3" for="subnet_mask"
            >Subnet mask</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              id="subnet_mask"
              name="subnet_mask"
              class="form-control"
              pattern="^(0|128|192|224|240|248|252|254|255)\.(0|128|192|224|240|248|252|254|255)\.(0|128|192|224|240|248|252|254|255)\.(0|128|192|224|240|248|252|254|255)$"
              data-invalidmsg="Please input a valid value for the IPv4 subnet mask"
            />
          </div>
        </div>
        <div
          class="form-group routerModeItemModal staticItemModal"
          style="display: none"
        >
          <label class="control-label col-sm-3" for="gateway"
            >Default gateway</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              id="gateway"
              name="gateway"
              class="form-control"
              pattern="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              data-invalidmsg="Please input a valid value for the IPv4 default gateway"
            />
          </div>
        </div>
        <div
          class="form-group routerModeItemModal staticItemModal"
          style="display: none"
        >
          <label class="control-label col-sm-3" for="dns1">DNS 1</label>
          <div class="col-sm-8">
            <input
              type="text"
              id="dns1"
              name="dns1"
              class="form-control"
              pattern="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              data-invalidmsg="Please input a valid value for the IPv4 primary DNS server"
            />
          </div>
        </div>
        <div
          class="form-group routerModeItemModal staticItemModal"
          style="display: none"
        >
          <label class="control-label col-sm-3" for="dns2">DNS 2</label>
          <div class="col-sm-8">
            <input
              type="text"
              id="dns2"
              name="dns2"
              class="form-control"
              pattern="^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              data-invalidmsg="Please input a valid value for the IPv4 secondary DNS server"
            />
          </div>
        </div>

        <div
          class="form-group routerModeItemModal pppoeItemModal"
          style="display: none"
        >
          <label class="control-label col-sm-3" for="username">Username</label>
          <div class="col-sm-8">
            <input
              type="text"
              id="username"
              name="username"
              class="form-control"
              pattern="^[!-~]{1,64}$"
              data-invalidmsg="Please input a valid value for the Username. A maximum of 64 non space characters is allowed."
            />
          </div>
        </div>
        <div
          class="form-group routerModeItemModal pppoeItemModal"
          style="display: none"
        >
          <label class="control-label col-sm-3" for="pppoePassword"
            >Password</label
          >
          <div class="col-sm-8">
            <input
              type="text"
              id="pppoePassword"
              name="pppoePassword"
              class="form-control"
              pattern="^[!-~]{1,64}$"
              data-invalidmsg="Please input a valid value for the Password. A maximum of 64 non space characters is allowed."
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="configureModalSpeed"
  #editModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  *ngIf="dataOnu"

>
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Configure service-port
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="">
          <div class="col-md-12">
            <label for=""><strong>User VLAN-ID</strong></label>
            <ng-select
              [items]="itemsVLAN"
              notFoundText="No se encontraron datos"
              placeholder="Seleccionar"
              bindLabel="name"
              bindValue="idOlt"
              [(ngModel)]="speedProfile.userVlanId"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
          </div>
          <div class="col-md-12">
            <label for=""><strong>Tag-transform mode</strong></label>
            <ng-select
              [items]="itemsTAG"
              notFoundText="No se encontraron datos"
              placeholder="Seleccionar"
              bindLabel="name"
              bindValue="idOlt"
              [(ngModel)]="speedProfile.TagtransformMode"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
          </div>
          <div class="col-md-12">
            <label for=""><strong>Download speed</strong></label>
            <ng-select
              [items]="itemsDownload"
              notFoundText="No se encontraron datos"
              placeholder="Seleccionar"
              bindLabel="name"
              bindValue="idOlt"
              [(ngModel)]="speedProfile.downloadSpeed"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
          </div>
          <div class="col-md-12">
            <label for=""><strong>Upload speed</strong></label>
            <ng-select
              [items]="itemsUpload"
              notFoundText="No se encontraron datos"
              placeholder="Seleccionar"
              bindLabel="name"
              bindValue="idOlt"
              [(ngModel)]="speedProfile.uploadSpeed"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success" (click)="submit(1)">
          Aceptar
        </button>
      </div>
    </div>
  </div>
</div>


