export const environment = {
  production: false,

  // ! URL API's
  UrlFull:'https://backend.thomas-talk.me/',
  urlApiMapbox: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  urlDBFULL: 'https://dbfull.thomas-talk.me/api/',
  apiUrl: 'https://apiolt.fibextelecom.net/api',
  apiAuthKey: 'base64:SBtbGojkg59dERW++4kH7ObuIi/A13VpOAe0d+nrbCs=',
  
  // ! BBDD
  bd:'thomas_clubfibex',
  
  // ! Tablas
  tableEmpConv:'cb_EmpresaConvenio',
  tableEmpFran:'cb_EmpFranquicia',
  tableEmpCat:'cb_EmpCategoria',
  tableEmpPromo:'cb_Promo',

  // ! Token & Auth
  tokenMapbox: 'pk.eyJ1IjoibHVpc3BlcmRvbW80MDgiLCJhIjoiY2xqaXpnY2o3MDQ3eTNncGI1YWN5eW1qZiJ9.IjpfPbexwEjcLNQ6JTt_1w',
  tokendbFull: '81N2vjsIqq39qjGoEmDmMtjLqW7gLDA7vBV-Ffwuhwf-evejDaRGMrdSASny480GVOl7fcYfh21xfcpJWZ8VzQBHf0chPGOhyo9w3zJQ8OXEYGxwzxCU1gDplt3ebE4wDCkoujh4284bTkzz52AbNudtcR1HBq5_xU3mL5IJ4pqbeiFOJVa9',
  authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),

  // ! Keys
  apiKey: 'dev',
  encryptionKey: 'asf1d2dwqd21',
  SecuryEncrypt: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
  ApiKey: "167871278349249",
  ApiSecret: "7I5iUVfNCJlWI8zS6E1vUSXM4oI",

  // ! Otros
  CloudName: "thomasv9",
  upload_preset: "itenw8im",


  //!env for logs
  logs:{
    table: 'cb_LogUser',
    id:'idLog'
  },

}