<div class="row">
    <div class="col-md-6 mb-3">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h4>Export OLTS information</h4>
                <div *ngIf="loadingOlt" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="card-body">
                <p>Export Information from all OLTs, you can export the Information in csv, pdf and excel</p>
                <div class="text-center" *ngIf="!loadingOlt">
                    <button title="Excel" (click)="exportExcel('olts')" class="btn btn-outline-success mx-0"><i class="fa fa-file-excel-o"></i></button>
                    <button title="CSV" (click)="exportToCSV('olts')" class="btn btn-outline-success mx-2"><i class="bi bi-filetype-csv"></i></button>
                    <button title="PDF" (click)="exportPDF('olts')" class="btn btn-outline-danger"><i class="fa fa-file-pdf-o"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 mb-3">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h4>Export ONUS information</h4>
                <button type="button" (click)="showSelectOlt()" *ngIf="!hiddenSelectOlt && !loadingOnu" class="btn btn-primary"><i class="fa fa-filter"></i></button>
                <ng-select [items]="itemsOlts" class="custom" *ngIf="hiddenSelectOlt && !loadingOnu" notFoundText="No se encontraron datos" placeholder="Seleccionar" bindLabel="name" bindValue="id" [(ngModel)]="selectedOlt">
                </ng-select>
                <button type="button" *ngIf="hiddenSelectOlt && !loadingOnu" (click)="hiddenSelect()" class="btn btn-outline-danger"><i class="fa fa-close"></i></button>
                <div *ngIf="loadingOnu" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="card-body">
                <p>Export Information from all ONUS, you can export the Information in csv, pdf and excel</p>
                <div *ngIf="!loadingOnu" class="text-center">
                    <button title="Excel" (click)="exportExcel('onus')" class="btn btn-outline-success mx-0"><i class="fa fa-file-excel-o"></i></button>
                    <button title="CSV" (click)="exportToCSV('onus')" class="btn btn-outline-success mx-2"><i class="bi bi-filetype-csv"></i></button>
                    <button title="PDF" (click)="exportPDF('onus')" class="btn btn-outline-danger"><i class="fa fa-file-pdf-o"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>
