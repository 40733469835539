<div class="col-md-12">
  <div class="card bd-primary">
    <div class="card-header bg-dark text-white">
      <span>{{ title }} list</span>
    
    </div>
    <div class="card-body pd-sm-30">
      <div class="table-responsive">
        <table
          class="table-striped table border"
          *ngIf="array.length > 0; else arrayEmpty"
        >
          <thead>
            <tr>
        
              <ng-container scope="col" *ngFor="let header of headers">
                <ng-container [ngSwitch]="header">
                  <th *ngSwitchCase="'pon_type_id'">
                    Type 
                  </th>
                  <th *ngSwitchDefault>
                    {{ header.includes("_") ? header.split("_").join(" ") : header == 'pon_type_id' ? 'Type' : header }}
                  </th>
                </ng-container>

              </ng-container>

              <th *ngIf="actionBtn">Action</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of getVisibleItems()">
              <ng-container *ngFor="let key of getObjectKeysWithoutId(item)">
                <ng-container [ngSwitch]="key">

                  <ng-container *ngIf="tab == 'uplinks'">
                    <td *ngSwitchCase="'status'" class="text-center">
                      <i [title]="item[key] == '1' ? 'UP' : item[key] == 2 ? 'DOWN' : 'UNKNOWN'"  [class]="switchStatusIcon(item[key] == '1' ? 'UP' : item[key] == 2 ? 'DOWN' : 'UNKNOWN')"></i>                    
                    </td>
                  </ng-container>

                  <ng-container *ngIf="tab != 'uplinks'">

                    <td *ngSwitchCase="'status'" class="text-center">
                      <i [title]="item[key]"  [class]="switchStatusIcon(item[key])"></i>
                    </td>

                  </ng-container>

                  <!-- PON PORTS ------------------->
                  
                  <td *ngSwitchCase="'average_signal'" class="text-center">
                    <i  [title]="item[key]" [class]="switchSignal(item[key])"></i>
                  </td>

                  <td *ngSwitchCase="'pon_type_id'" class="text-center" id="">
                    {{ item[key] == 1 ? "GPON" : item[key] == 2 ? "EPON" : "GPON / EPON" }}
                  </td>

                  <td *ngSwitchCase="'operational_status'"> 
                    <i [title]="item[key] == 1 ? 'UP' : item[key] == 2 ? 'DOWN' : 'UNKNOWN'"  [class]="switchStatusIcon(item[key] == 1 ? 'UP' : item[key] == 2 ? 'DOWN' : 'UNKNOWN')"></i>
                  </td>

                  <td *ngSwitchCase="'admin_status'" class="text-center" id="">
                    {{ item[key] == 1 ? "ENABLED" : item[key] == 2 ? "DISABLED" : "UNKNOWN" }}
                  </td>

                  <!-- UPLINKS ------------ -->

                  <ng-container *ngIf="tab == 'uplinks'">
                    <td *ngSwitchCase="'type'" class="text-center" id="">
                      {{ item[key] == "6" ? "Ethernet CSMA/CD" : "Fast Ethernet" }}
                    </td>
                  </ng-container>

                  <td *ngSwitchCase="'admin_state'" class="text-center" id="">
                    {{ item[key] == 1 ? "ENABLED" : item[key] == 2 ? "DISABLED" : "UNKNOWN" }}
                  </td>

                  <td *ngSwitchCase="'updated_at'" class="text-center" id="">
                    {{ item[key] | date:'MMMM d y, h:mm:ss a' }}
                  </td>

                  <!-- VLANS---------------- -->

                  <td *ngSwitchCase="'multicast_vlan'" class="text-center" id="">
                    <i
                    [class]="item[key]? 'fa fa-check-square text-success' : 'fa fa-window-close text-danger'"></i>                    
                  </td>

                  <td *ngSwitchCase="'management_voip'" class="text-center" id="">
                    <i
                    [class]="item[key]? 'fa fa-check-square text-success' : 'fa fa-window-close text-danger'"></i>                    
                  </td>

                  <td *ngSwitchCase="'dhcp_snooping'" class="text-center" id="">
                    <i
                    [class]="item[key]? 'fa fa-check-square text-success' : 'fa fa-window-close text-danger'"></i>                    
                  </td>

                  <td *ngSwitchCase="'lan_to_lan'" class="text-center" id="">
                    <i
                    [class]="item[key]? 'fa fa-check-square text-success' : 'fa fa-window-close text-danger'"></i>                    
                  </td>

                  <td *ngSwitchDefault>
                    {{ item[key] }}
                  </td>

                </ng-container>
              </ng-container>

              <td *ngIf="tab == 'oltCards'" class="text-center">
                <button class="btn btn-primary">Reboot card</button>
              </td>

              <td *ngIf="tab == 'ponPorts'">
                <button data-toggle="modal" data-target="#configurePonModal" class="btn btn-primary">Configure</button>
              </td>

              <td  *ngIf="tab == 'uplinks'">
                <button data-toggle="modal" data-target="#configureuplink" class="btn btn-primary">Configure</button>
              </td>

              <td *ngIf="tab == 'vlans'">
                <button class="btn btn-danger">Delete</button>
              </td>

            </tr>
          </tbody>
        </table>
        <ng-template #arrayEmpty>
          <h5 class="text-center">No results</h5>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="btn-pagination align-items-center">
    <button
      class="btn btn-list"
      (click)="previousPage()"
      [disabled]="currentPage === 1"
    >
      Previous
    </button>
    <span class="number">{{ currentPage }} of {{ getTotalPages() }}</span>
    <button
      class="btn btn-list"
      (click)="nextPage()"
      [disabled]="currentPage === getTotalPages()"
    >
      Next
    </button>
  </div>
</div>
