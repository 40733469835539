<div class="sh-headpanel-right">
  <!-- <div class="dropdown mg-r-10">
    <a routerLink="" class="dropdown-link dropdown-link-notification">
      <i class="icon ion-ios-filing-outline tx-24"></i>
    </a>
  </div> -->
  <!-- <div class="dropdown dropdown-notification">
    <a routerLink="" data-toggle="dropdown" class="dropdown-link dropdown-link-notification">
      <i class="icon ion-ios-bell-outline tx-24"></i>
      <span class="square-8"></span>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
      <div class="dropdown-menu-header">
        <label>Notifications</label>
        <a routerLink="">Mark All as Read</a>
      </div><!-- d-flex -->
      <!-- <div class="media-list"> -->
        <!-- loop starts here -->
        <!-- <a *ngFor="let notification of notifications" routerLink="" class="media-list-link read">
          <div class="media pd-x-20 pd-y-15">
            <img [src]="notification.img" class="wd-40 rounded-circle" alt="">
            <div class="media-body">
              <p class="tx-13 mg-b-0"><strong
                  class="tx-medium">{{notification.title?notification.title:""}}</strong>{{notification.descrip?notification.descrip:""}}
              </p>
              <span class="tx-12">{{notification.date?notification.date:""}}</span>
            </div> -->
          <!-- </div>media -->
        <!-- </a> -->
        <!-- loop ends here -->
        <!-- <div class="media-list-footer">
          <a routerLink="" class="tx-12"><i class="fa fa-angle-down mg-r-5"></i> Show All Notifications</a>
        </div>
      </div><!-- media-list -->
    <!-- </div>dropdown-menu -->
  <!-- </div> -->
  <app-profile-options [profileBtns]="profileDdwnBtns"></app-profile-options>
</div><!-- sh-headpanel-right -->
