<!-- PARA RENDERIZAR FORMULARIOS EN FUNCION DE LOS STEPS QUE SE PASEN POR PARAMETROS, EN DESARROLLO -->

<!-- <ng-container *ngFor="let step of param; index as i">
  <h1 *ngIf="i === stepCounter">PASO {{i}}</h1>
  <app-new-user-sidebar *ngIf="i === stepCounter" [param]="step" (SalidaClickMenu)="emitter($event)" (emitter)="next($event)"></app-new-user-sidebar>

  <button *ngIf="i === stepCounter" class="btn btn-danger" (click)="next()">Siguiente</button>
</ng-container> -->

<app-new-user-sidebar [param]="param" (SalidaClickMenu)="emitter($event)"></app-new-user-sidebar>

