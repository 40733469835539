<div class="dropdown custom-dropdown">
  <input [name]="name" hidden type="file" #uploader (change)="uploadImagePayment2($event)" />
  <a href="#" data-toggle="dropdown" class="dropdown-link rounded-circle bd" aria-haspopup="true" aria-expanded="false">
    <img (mouseenter)="showEditimg=true;" [src]="UrlIMg !=''? UrlIMg : ImgDefecto"
      class="wd-100 ht-100 rounded-circle pd-2" alt="">
  </a>
  <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
    <a role="button" class="dropdown-item" *ngIf="showEditimg" (click)="uploader.click()">{{UrlIMg != ImgDefecto? "Editar Imagen" : "Subir Imagen"}}</a>
    <a role="button" class="dropdown-item" *ngIf="showEditimg && UrlIMg != ImgDefecto" (click)="RemoveAvatar()">Eliminar Imagen</a>
  </div>
</div>
<!-- <div class="media align-items-center" (mouseenter)="showEditimg=true;">
  <div>
    <button class="ButtonCustomise" *ngIf="showEditimg" (click)="uploader.click()" (mouseleave)="showEditimg=false;">
      <i class="fa fa-pencil" style='font-size:24px; color: rgba(255, 255, 255, 0.87); margin-left:45px;'></i>
    </button>
    <button class="ButtonCustomise" *ngIf="showEditimg" (click)="RemoveAvatar()" (mouseleave)="showEditimg=false;">
      <i class="fa fa-trash" style='font-size:24px; color: rgba(255, 255, 255, 0.87); margin-left:15px;'
        aria-hidden="true"></i>
    </button>
  </div>
  <img [src]="UrlIMg !=''? UrlIMg : ImgDefecto" class="wd-100 ht-100 rounded-circle bd pd-5" alt="">
</div> -->
<!-- <div [class.ImageBlack] = "showEditimg" [style.backgroundImage]="'url('+UrlIMg+')'"> -->
<!-- <div class="overlay"></div> -->
<!-- </div> -->
