<div class="d-flex p-2">
    <div class="col-md-2">
        <div class="form-group">
            <label class="control-label"> Select OLT</label>
            <ng-select [items]="itemsOlts" (change)="selectOlt(selectedOlt)" [clearable]="false"
                notFoundText="No data found" placeholder="Select OTL" bindLabel="name" bindValue="name"
                [(ngModel)]="selectedOlt">
            </ng-select>
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label class="control-label">Filter</label>
            <input type="text" class="form-control" placeholder="SN, MAC, IP, name, address" name="filters"
                [(ngModel)]="filters" (ngModelChange)="onInputChange()" />
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label for="status" class="control-label">Status / Signal</label>
            <ul class="pagination">
                <li class="status-filter mr-2" #online value="online" (click)="selectStatus('online')" title="Online">
                    <span><i class="fa fa-globe fa-sm text-green btn btn-light"
                            [ngClass]="filters2 == 'online' ? 'active' : ''"></i></span>
                </li>
                <li class="status-filter mr-2" #powerFail value="powerfail" title="Power Fail"
                    (click)="selectStatus('power fail')">
                    <span><i class="fa fa-plug fa-sm text-grey btn btn-light"
                            [ngClass]="filters2 == 'power fail' ? 'active' : ''"></i></span>
                </li>
                <li class="status-filter mr-2" #los value="los" title="Los Of Signal" (click)="selectStatus('LOS')">
                    <span><i class="fa fa-chain-broken fa-sm text-red btn btn-light"
                            [ngClass]="filters2 == 'LOS' ? 'active' : ''"></i></span>
                </li>
                <li class="status-filter mr-2" #offline value="offline" title="Offline"
                    (click)="selectStatus('offline')">
                    <span><i class="fa fa-globe fa-sm text-red btn btn-light"
                            [ngClass]="filters2 == 'offline' ? 'active' : ''"></i></span>
                </li>
                <li class="status-filter mr-2" #disabled value="disabled" title="Disabled"
                    (click)="selectStatus('Disabled')">
                    <span><i class="fa fa-ban fa-sm text-grey btn btn-light"
                            [ngClass]="filters2 == 'Disabled' ? 'active' : ''"></i></span>
                </li>
                <li class="status-filter mr-2" value="very good" (click)="selectStatus('Very good')" title="Very good">
                    <span><i class="fa fa-signal fa-md text-success btn btn-light"
                            [ngClass]="filters2 == 'very good' ? 'active' : ''"></i></span>
                </li>
                <li class="status-filter mr-2" value="warning" title="Warning" (click)="selectStatus('Warning')">
                    <span><i class="fa fa-signal fa-md text-warning btn btn-light"
                            [ngClass]="filters2 == 'warning' ? 'active' : ''"></i></span>
                </li>
                <li class="status-filter mr-2" value="critical" title="Critical" (click)="selectStatus('Critical')">
                    <span><i class="fa fa-signal fa-md text-danger btn btn-light"
                            [ngClass]="filters2 == 'critical' ? 'active' : ''"></i></span>
                </li>
            </ul>
        </div>
    </div>
</div>