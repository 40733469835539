import { Component, OnInit,Input, Output, ViewChild, ElementRef } from '@angular/core';
//import { Ngmo } from 'ng-bootstrap';

interface EstructuraModal{
  title: string,
  texto: string,
  botonsave: string,
  botonclose: string
}

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent implements OnInit {
  @ViewChild('login') modalDom!: ElementRef;
  
  @Input() Modal!: EstructuraModal;

  constructor() { }

  ngOnInit() {
    let estructura={
      title:"Ejemplo test",
      texto: "Esto es una simple prueba",
      botonsave: "Aceptar",
      botonclose: "Cerrar"
    }
    this.Modal=estructura;
    //this.abrirModal(this.modalDom,"Hola mundo");
  }

  // abrirModal(modal:any, mensajeInicial: any){
  //   //this.mensajeInicial = mensajeInicial;
  //   this.modalService.open(modal);
  // }

}
