import { Injectable, EventEmitter } from '@angular/core';
import { ObserverTableEvents } from 'src/app/shared/interfaces/table.interface';

@Injectable({
  providedIn: 'root'
})
export class ObservableComponentsService {

  nextObj = new EventEmitter<ObserverTableEvents>();
  prevObj = new EventEmitter<ObserverTableEvents>();
  modifyTotalReg = new EventEmitter<ObserverTableEvents>();


  // ! Obervable para componente *boton*
  paramData$: EventEmitter<boolean> = new EventEmitter<boolean>();
  pageTitleOptions$: EventEmitter<string> = new EventEmitter<string>();

  constructor() {

    // this.paramData$.subscribe(res => console.log(res))
  }

}
