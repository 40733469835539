import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IModule } from 'src/app/core/interfaces/IModule.interface';
import { DashboardModulesService } from 'src/app/core/services/dashboard-modules.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';

@Component({
  selector: 'app-headpanel',
  templateUrl: './headpanel.component.html',
  styleUrls: ['./headpanel.component.scss']
})
export class HeadpanelComponent implements OnInit {

  public btnsLeft!: IModule | any;
  public btnsRight!: IModule | any;
  public profileDdwnBtns!: IModule | any;

  constructor() {

    // this.btnsRight = [
    //   {
    //     "id": 1,
    //     "name": "Promociones",
    //     "order": 1,
    //     "route": "/dashboard/tags/promociones",
    //     "styleRules": {
    //       "icon": "fa fa-star"
    //     },
    //     "childrenModules": []
    //   },
    //   {
    //     "id": 2,
    //     "name": "Cupones",
    //     "order": 2,
    //     "route": "/dashboard/tags/cupones",
    //     "styleRules": {
    //       "icon": "fa fa-ticket"
    //     },
    //     "childrenModules": []
    //   },
    // ]
    // this.btnsLeft = [
    //   {
    //     "id": 1,
    //     "name": "Promociones",
    //     "order": 1,
    //     "route": "/dashboard/tags/promociones",
    //     "styleRules": {
    //       "icon": "fa fa-star"
    //     },
    //     "childrenModules": []
    //   },
    //   {
    //     "id": 2,
    //     "name": "Cupones",
    //     "order": 2,
    //     "route": "/dashboard/tags/cupones",
    //     "styleRules": {
    //       "icon": "fa fa-ticket"
    //     },
    //     "childrenModules": []
    //   },
    // ]
    // this.profileDdwnBtns = [
    //   {
    //     "id": 1,
    //     "name": "Editar Perfil",
    //     "order": 1,
    //     "route": "/dashboard/tags/perfil",
    //     "styleRules": {
    //       "icon": "icon ion-ios-person"
    //     },
    //     "childrenModules": []
    //   },
    //   {
    //     "id": 2,
    //     "name": "Configuración",
    //     "order": 2,
    //     "route": null,
    //     "method": null,
    //     "styleRules": {
    //       "icon": "icon ion-ios-gear"
    //     },
    //     "childrenModules": []
    //   },
    //   // {
    //   //   "id": 3,
    //   //   "name": "Descargas",
    //   //   "order": 3,
    //   //   "route": null,
    //   //   "method": null,
    //   //   "styleRules": {
    //   //     "icon": "icon ion-ios-download"
    //   //   },
    //   //   "childrenModules": []
    //   // },
    //   // {
    //   //   "id": 4,
    //   //   "name": "Favoritos",
    //   //   "order": 4,
    //   //   "route": null,
    //   //   "method": null,
    //   //   "styleRules": {
    //   //     "icon": "icon ion-ios-star"
    //   //   },
    //   //   "childrenModules": []
    //   // }
    // ]
  }

  ngOnInit(): void {
  }
}
