<div class="row p-3">
    <div class="col-md-6">
        <h4 class="text-bold">Import ONUs location details</h4>

        <p>
            Use the downloaded Reports or Export file and edit it (in Excel or OpenOffice) with the details of
            location of the ONUs you want to update
            The file must include the following header columns: ONU External ID, Name, Zone,
            address,
            latitude, longitude, ODB (divisor)
            Only these fields will be updated in the database.
        </p>

        <form action="" [formGroup]="form" class="form">

           <div class="d-flex flex-column align-items-start">
     
            <div class="d-flex">
              
                <button [disabled]="loading" class="btn btn-primary mt-5 mb-0 mr-2"><label *ngIf="!loading" for="file" class="m-0">ONUs details file</label> <p class="m-0" *ngIf="loading">ONUs details file</p></button>
                <input type="file" id="file" class="form-control d-none" formControlName="file" accept=".csv, .xlsx" (change)="csvImport($event)">
                <button class="btn btn-danger mt-5" *ngIf="file && !loading" (click)="reset()"><i class="fa fa-times" aria-hidden="true"></i></button>
            </div>

            <p *ngIf="file">{{file.name}}</p>

           </div>



            <button class="btn btn-primary mt-5" (click)="submitImport()" [disabled]="file == null || loading || invalid.int || invalid.string">Import ONUs location Details <i *ngIf="loading" class="fa fa-spinner fa-spin margin-left"></i></button>

        </form>

    
       
    </div>
    <!-- <div class="col-md-6">
        <div class="justify-content-center d-flex align-items-center">
        </div>
    </div> -->
</div>