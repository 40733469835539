<div class="sh-pagebody">
    <div *ngIf="openModalCreate" class="list-table2 list-table2-showsize">
        <app-new-user-sidebar-stepper *ngIf="DataFormOpenArray" [params]="DataFormOpenArray" (SalidaClickMenu)="toCloseCreateElement($event)"></app-new-user-sidebar-stepper>
        <!-- <app-new-user-sidebar [param]="DataFormOpen" (SalidaClickMenu)="toCloseCreateElement()"></app-new-user-sidebar> -->
    </div>
    <!-- Aqui se debe aplicar ngClass / tanto aqui como arriba-->
    <!-- <div *ngIf="!openModalCreate && !openModalCreateInit" class="list-table2 list-table2-hiddensize">
        <app-new-user-sidebar  (SalidaClickMenu)="toCloseCreateElement()"></app-new-user-sidebar>
    </div> -->
    <div *ngIf="openModalCreate" (click)="toCloseCreateElement()" class="list-table2-show" style="background-color: rgba(0,0,0,0.4); left: 0; top: 0px; position: absolute; width: 100%; height: 100%; z-index: 80;"></div>

    <div class="card bd-primary mg-t-20">
      <div class="card-header bg-primary tx-white">Productos</div>
      <div class="card-body pd-sm-30">

        <!-- <div class="d-inline-block"> -->
            <!-- <div (click)="toOpenMenu()" style="position: relative; float: right; cursor: pointer; color:dodgerblue">
                FILTRO <i class="icon ion-settings"></i>

            </div> -->
            <div *ngIf="showMenu" class="menu-dropdown" aria-labelledby="dropdownBasic1">
                <button *ngFor="let head of FieldAndHead?.head" ngbDropdownItem>{{ head }}</button>
            </div>
			<!-- <button type="button"  class="btn btn-outline-primary" id="dropdownBasic1" style="position: relative;">
				Toggle dropdown
			</button> -->
		<!-- </div> -->
        <p class="mg-b-20 mg-sm-b-30">{{ Atributos?.Description }}</p>

        <div class="table-wrapper">
            <div [class]="['table-responsive'].concat(tableClasses || '')">
                <table id="datatable-crud" class="table display responsive nowrap">
                    <thead>
                        <tr>
                            <th *ngFor="let head of FieldAndHead?.head" class="wd-15p">{{ head }}</th>
                            <th>Detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ArrayDataAll">  
                            <td *ngFor="let key of validFields;">
                                {{ item[key] || 'S/I' }}
                            </td>
                            <td style="text-align: center;">
                                <a  class="icon ion-android-arrow-dropdown dt-control" style="cursor: pointer;" (click)="toOpenCreateElement(item)"></a>
                            </td> 
                        </tr>
                        <tr *ngIf="showClientData">
                            <th [attr.colspan]="FieldAndHead?.head.length + 1" *ngIf="openCreateElement && idToCompare == element['idEmpConv']">
                              <app-new-user-sidebar class="fs-nus" [param]="rowData" [element]="element" (SalidaClickMenu)="toCloseCreateElement($event)"></app-new-user-sidebar>
                            </th>
                        </tr>
                        <!-- <ng-container  *ngFor="let items of FieldAndHead?.data">
                            <tr>
                                <ng-container  *ngFor="let item of items; let i = index">
                                    <td *ngIf="i < 4">{{ item }}</td>
                                </ng-container>
                                <td style="text-align: center;">
                                    <a  class="icon ion-android-arrow-dropdown dt-control" style="cursor: pointer;" (click)="toOpenCreateElement(items)"></a>
                                </td>
                            </tr>
                            <tr *ngIf="showClientData">
                              <th [attr.colspan]="FieldAndHead?.head.length + 1" *ngIf="openCreateElement && idToCompare == items[items.length-1]">
                                <app-new-user-sidebar class="fs-nus" [param]="rowData" [element]="this.element" (SalidaClickMenu)="toCloseCreateElement($event)"></app-new-user-sidebar>
                              </th>
                            </tr>
                        </ng-container> -->
                            <!-- <tr [hidden]="selectedProduct[3] != items[3]">
                                <ng-container>
                                    <ng-container *ngTemplateOutlet="rowDetailsTemplate;"></ng-container>
                                </ng-container>
                            </tr> -->
                        <tr *ngIf="loading">Cargando...</tr>

                    </tbody>

                </table>
            </div>
        </div>
    <ng-template
        #rowDetailsTemplate
        let-product >
        <div>
            <div style="width: 850%; padding: 10px 10px 10px 10px;">
                <!-- Selected product form -->
                <form>
                    <div class="form-group">
                      <label for="email2">Email address:</label>
                      <input type="email" class="form-control" id="email2">
                    </div>
                    <div class="form-group">
                      <label for="pwd2">Password:</label>
                      <input type="password" class="form-control" id="pwd2">
                    </div>
                    <div class="checkbox">
                      <label><input type="checkbox"> Remember me</label>
                    </div>
                    <button type="submit" class="btn btn-default">Submit</button>
                  </form>
            </div>
        </div>
    </ng-template><!-- table-wrapper -->
       </div><!-- card-body -->
    </div><!-- card -->
</div><!-- sh-pagebody -->
