<!-- Select -->
<div class="col-lg-12 mg-t-20 mg-lg-t-0">
  <select #selectList class="form-control select2-show-search" id="select2-state" data-placeholder="Choose one (with searchbox)" (click)="setValue(selectList.value)">
    <option value=""></option>
    <option *ngFor="let title of ArrayDataIn" [value]="title.value" [selected]="title.value === valueUserEmp">{{title.datoShow}}</option> 
  </select>
</div>
 <!--
<app-list-table1 [FieldAndHead]="'TempTestTableData'"></app-list-table1>-->


