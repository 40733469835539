<div class="row mb-5">

  <div class="col-md-3 col-6">
    <div class="card bd-0" style="border-radius: 15px; overflow: hidden;">
      <div class="card-header d-flex align-items-center justify-content-between bg-dash tx-white border-0">

        <div class="flex-column w-100 mobile">
          
          <div class="d-flex justify-content-between align-items-center">

            <i class="sh-pagetitle-icon fa fa-magic fa-3x border-content"></i>

            <div class="card-option tx-24">
              <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
              <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
              <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
            </div><!-- card-option -->

          </div>


          <h3 class="mg-b-0 tx-700" style="text-align: center;">Stand by</h3>
        </div>
        
        <i class="sh-pagetitle-icon fa fa-magic fa-2x desk"></i>

        <h3 class="mg-b-0 tx-700 desk">Stand by</h3>
        <div class="card-option tx-24 desk">
          <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
          <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
          <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
        </div><!-- card-option -->
      </div><!-- card-header -->
      <div class="card-body bg-dash tx-white d-flex align-items-center justify-content-center">
        <div *ngIf="loading;" class="div-centrado ">
          <div class="lds-ring card-load">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
        <p  *ngIf="!loading" class="mg-b-0 tx-24 tx-bold w-100"  style="cursor: pointer; text-align: center;" (click)="redirect('unconfigured')">{{onus.espera}}</p>
      </div><!-- card-body -->
    </div>
  </div>

  <div class="col-md-3 col-6">
    <div class="card bd-0" style="border-radius: 15px; overflow: hidden;">
      <div class="card-header d-flex align-items-center justify-content-between bg-dash tx-white border-0">

        <div class="flex-column w-100 mobile">
          
          <div class="d-flex justify-content-between align-items-center">

            <i class="sh-pagetitle-icon fa fa-tasks fa-3x"></i>

            <div class="card-option tx-24">
              <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
              <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
              <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
            </div><!-- card-option -->

          </div>


          <h3 class="mg-b-0 tx-700" style="text-align: center;">Online</h3>
        </div>

        <i class="sh-pagetitle-icon fa fa-tasks fa-3x desk"></i>

        <h3 class="mg-b-0 tx-700 desk">Online</h3>
        <div class="card-option tx-24 desk">
          <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
          <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
          <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
        </div><!-- card-option -->
      </div><!-- card-header -->
      <div class="card-body bg-dash tx-white d-flex align-items-center justify-content-center">
        <div *ngIf="loading;" class="div-centrado ">
          <div class="lds-ring card-load">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
        <p *ngIf="!loading" class="mg-b-0 tx-24 tx-bold w-100"  style="cursor: pointer; text-align: center;" (click)="redirect('configured')">{{onus.online}}</p>
      </div><!-- card-body -->
    </div>
  </div>

  <div class="col-md-3 col-6">
    <div class="card bd-0" style="border-radius: 15px; overflow: hidden;">
      <div class="card-header d-flex align-items-center justify-content-between bg-dash tx-white border-0">

        <div class="flex-column w-100 mobile">
          
          <div class="d-flex justify-content-between align-items-center">

            <i class="sh-pagetitle-icon fa fa-close fa-3x"></i>

            <div class="card-option tx-24">
              <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
              <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
              <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
            </div><!-- card-option -->

          </div>


          <h3 class="mg-b-0 tx-700"  style="text-align: center;">Offline</h3>
        </div>

        <i class="sh-pagetitle-icon fa fa-close fa-sm desk"></i>

        <h3 class="mg-b-0 tx-700 desk">Offline</h3>
        <div class="card-option tx-24 desk">
          <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
          <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
          <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
        </div><!-- card-option -->
      </div><!-- card-header -->
      <div class="card-body bg-dash tx-white d-flex align-items-center justify-content-center">
        <div *ngIf="loading;" class="div-centrado ">
          <div class="lds-ring card-load">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
        <p *ngIf="!loading" class="mg-b-0 tx-24 tx-bold w-100"  style="cursor: pointer; text-align: center;" (click)="redirect('configured')">{{onus.offline}}</p>
      </div><!-- card-body -->
    </div>
  </div>


  <div class="col-md-3 col-6">
    <div class="card bd-0" style="border-radius: 15px; overflow: hidden;">
      <div class="card-header d-flex align-items-center justify-content-between bg-dash tx-white border-0">

        <div class="flex-column w-100 mobile">
          
          <div class="d-flex justify-content-between align-items-center">

            <i class="fa fa-signal" aria-hidden="true"></i>        
            <div class="card-option tx-24">
              <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
              <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
              <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
            </div><!-- card-option -->

          </div>


          <h3 class="mg-b-0 tx-700"  style="text-align: center;">Low signal</h3>
        </div>

        <i class="sh-pagetitle-icon fa fa-signal desk" aria-hidden="true"></i>        
        <h3 class="mg-b-0 tx-700 desk">Low signal</h3>
        <div class="card-option tx-24 desk">
          <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button>
          <!-- <a href="" class="tx-white-7 hover-white mg-l-10"><i class="icon ion-ios-arrow-down lh-0"></i></a> -->
          <!-- <button class="opt-btn"><i class="icon ion-android-more-vertical lh-0"></i></button> -->
        </div><!-- card-option -->
      </div><!-- card-header -->
      <div class="card-body bg-dash tx-white d-flex align-items-center justify-content-center">
        <div *ngIf="loading;" class="div-centrado ">
          <div class="lds-ring card-load">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
        <p *ngIf="!loading" class="mg-b-0 tx-24 tx-bold w-100"  style="cursor: pointer; text-align: center;" (click)="redirect('configured')">{{onus.low}}</p>
      </div><!-- card-body -->
    </div>
  </div>

</div>

<div class="row mb-5">
  <div class="col-md-8 mb-5">

    <div *ngIf="loading" class="div-centrado w-100 h-100 d-flex justify-content-center align-items-center">
      <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
    </div>

    <app-chart *ngIf="!loading" idChart="netStatus" title="Network status" subtitle="onus" [labels]="['Abril', 'Mayo', 'Junio', 'Julio']" [data]="[89, 101, 99, 132]" type="line"></app-chart>
  </div>

  <div class="col-md-4">

    <app-scroll-card (filterData)="getFilteredData($event)" cardTitle="OLTs" [info]="olts" [filter]="true" type="list"></app-scroll-card>

  </div>

</div>

<div class="row mb-5">
  <div class="col-md-8 mb-5">

    <div *ngIf="loading" class="div-centrado w-100 h-100 d-flex justify-content-center align-items-center">
      <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
    </div>

    <app-chart *ngIf="!loading" idChart="onus" title="ONUS authorizations per day" subtitle="onus" [labels]="['Abril', 'Mayo', 'Junio', 'Julio']" [data]="[89, 101, 99, 132]" type="line"></app-chart>
  </div>

  <div class="col-md-4">

    <app-scroll-card  cardTitle="Record" [info]="infoEx" type="list"></app-scroll-card>

  </div>

</div>

<div class="row mb-5">
  <div class="col-md-8">
    <app-scroll-card *ngIf="this.olts.length" icon="fa fa-heartbeat" cardTitle="PON outage" [info]="[onus, olts]" type="table"></app-scroll-card>
  </div>




</div>