<div class='{{"extensible-select-input" + (opened ? " opened" : "")}}'>
    <input 
        readonly 
        type="text" 
        class="form-control" 
        (focus)="Focus($event)" 
        (blur)="OnBlur()" 
        (click)="Click($event)"
        [value]="getValue()"
    />
    <i class="aside-icon"></i>
    <div class="main-menu">
        <button type="button" class="menu-add-item-extensible" (click)="OpenModal()" (focus)="Focus($event)" (blur)="OnBlur()">
            <i class="fa fa-plus"></i> Agregar
        </button>
        <div *ngIf="!Field.length" class="menu-item-extensible-text">
            Sin contenido
        </div>
        <button type="button" class="{{'menu-item-extensible' + (isSelected(opt.value) ? ' selected' : '')}}" *ngFor="let opt of Field" (click)="SelectItem(opt.value)" (focus)="Focus($event)" (blur)="OnBlur()" >{{opt.datoShow}}</button>
    </div>

    <div *ngIf="modal" class="modal-background" (click)="CloseModal()">
        <div class="modal-container" (click)="ClickContainer($event)" (submit)="AddValueForm($event)">
            <app-new-user-sidebar [param]='{TitleHead: TitleShow,  Table: TableName,  Inputs: [],  Value:[]}' (SalidaClickMenu)="Salida($event)"></app-new-user-sidebar>
        </div>
    </div>
</div>