import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = {vars:{}, error: {}};
  // @Output() outdata = new EventEmitter<{name: string, value: string, isInv

  public msgInvalid: string | undefined;
  public status: boolean | undefined;
  
  HandlerOnKey() {
    delete this.formData.error[this.data.Campo];
  }
  
  ToggleValue() {
    // console.log(this.value);
    // this.outdata.emit({name: this.data.Campo, value: !this.value, isInvalid: Boolean(this.msgInvalid)});
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = !this.formData.vars[this.data.Campo];
  }
}
