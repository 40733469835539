import { Component, inject, Renderer2 } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss']
})
export class DiagnosticsComponent {

  smartOltService = inject(SmartOltService);
  render = inject(Renderer2);

  data: any = [];
  dataList: any = [];
  loading = true;
  page: number = 1;
  selectedOlt: any = null;
  itemsOlts: any = [];
  path: any;
  filters: string = '';
  filters2!: any;
  mostrar: any = null;
  numDatosMostrados: any = 10;
  aux: any = [];
  title:string = 'Configured';
  endPoint:string = 'onus';
  buttonsHide: boolean = true;
  viewStatus: boolean =true;
  showFilter: boolean = true;

  constructor(private filterPipe: FilterPipe, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.getData();
  }

  async getData() {

    let olts = await this.smartOltService.getData('olts/listing');
    let res = await this.smartOltService.getData('onus/listing');

    this.data = res.data;
    this.aux = this.data;

    if (this.data.length > 0) {

      this.itemsOlts = olts.data;
      this.loading = false;
    } else {
      this.loading = false;
      this.data = [];
    }
    
    if (this.numDatosMostrados != 10 || this.mostrar != null) {
      this.numDatosMostrados = 10;
      this.mostrar = null;
    }
    if (this.route.snapshot.queryParams['id']) {
      let olt = this.route.snapshot.queryParams['id']

      this.selectedOlt = olt;
    }
    this.route.queryParams.subscribe(async (data: any) => {
      if (data.path) {

        this.path = data.path;
      }
    });
    
  }

  refresh() {

    this.loading = true;
    this.selectedOlt = null;
    this.filters = '';
    this.filters2 = -1;
    this.getData();
    this.router.navigate(['/dashboard/diagnostics']);

  }
}