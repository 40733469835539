<div class="sh-headpanel-left">
  <ng-container *ngFor="let btnLeft of navBtnsLeft">
    <a [routerLink]="btnLeft.route" class="sh-icon-link">
      <div style="height: 25%;">
        <i [class]="btnLeft.styleRules?.icon"></i>
        <span>{{ btnLeft.name }}</span>
      </div>
    </a>
  </ng-container>
  <!-- END: HIDDEN IN MOBILE -->
  <div class="dropdown dropdown-app-list">
    <a href="" data-toggle="dropdown" class="dropdown-link">
      <i class="icon ion-ios-keypad tx-18"></i>
    </a>
    <div class="dropdown-menu">
      <ng-container *ngFor="let btnLeft of navBtnsLeft">
        <div class="row no-gutters">
          <div class="col-4">
            <a [routerLink]="btnLeft.route" class="dropdown-menu-link"
              style="display: 'flex'; justify-content: center;">
              <div style="height: 20px;">
                <i [class]="btnLeft.styleRules?.icon" style="padding-bottom: 23px;"></i>
                <span>{{ btnLeft.name }}</span>
              </div>
            </a>
          </div><!-- col-4 -->
        </div><!-- row -->
      </ng-container>
    </div><!-- dropdown-menu -->
  </div><!-- dropdown -->
