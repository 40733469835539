import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardModulesService } from 'src/app/core/services/dashboard-modules.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { AvatarGenericComponent } from 'src/app/shared/components/avatar-generic/avatar-generic.component';
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent {

  public cdata = {
    TitleHead: "Editar Perfil",
    Table: "cb_EmpresaUser",
    Inputs: [],
    Value:["idUserEmp_3jKgVz"]//"idUserEmp_p1YdX_"
  }

  constructor(
    private router: Router,
    private encryptionService: EncryptionService,
    public dashboardModulesService: DashboardModulesService
  ) { }











}



