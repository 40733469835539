<input
type="text"
class="form-control"
[placeholder]="data.PlaceHolder + (!!data.Requerido ? '*' : '')"
[name]="data.Campo"
[required]="!!data.Requerido"
(change)="HanderOnChange($event)"
(keypressed)="HandlerOnKey($event)"
(blur)="HanderOnChange($event)"
[maxLength]="data.MaxLen"
[hidden]="!data.ShowInsert"
[value]="value"
[pattern]="data.ExpresionReg"
/>
<div style="color: red" *ngIf="formData.error[data.Campo]">{{formData.error[data.Campo]}}</div>