<!-- Modal Agregar -->

<div class="modal fade" id="newModal" #newModal tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Add {{title}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <ng-container *ngFor="let section of formSections">
                        <div class="form-group col-md-12">
                            <label for="{{ section.controlName }}">{{ section.title }}</label>
                            <ng-container *ngIf="section.type !== 'checkbox' && section.type !== 'file'">
                                <ng-container *ngIf="section.type === 'select'">
                                    <ng-select [items]="section.selectOptions" notFoundText="No se encontraron datos"
                                        [formControlName]="section.controlName" bindLabel="name" bindValue="id"
                                        [placeholder]="section.title" [(ngModel)]="section.selectedItem">
                                    </ng-select>
                                </ng-container>
                                <input *ngIf="section.type === 'text' || section.type === 'number'" [type]="section.type" class="form-control" [formControlName]="section.controlName"
                                    [placeholder]="section.title">
                            </ng-container>
                            <ng-container *ngIf="section.type === 'radio'">
                                <div *ngFor="let option of section.radioOptions">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" [formControlName]="section.controlName" [id]="option.value" [value]="option.value">
                                    <label class="form-check-label" [for]="option.value">{{ option.label }}</label>
                                  </div>
                                </div>
                              </ng-container>
                            <ng-container *ngIf="section.type === 'checkbox'">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" [formControlName]="section.controlName">
                                  <label class="form-check-label">{{ section.title }}</label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="section.type === 'file'">
                                <input type="file" class="form-control" [formControlName]="section.controlName">
                            </ng-container>
                            <span class="text-danger mx-1"
                                *ngIf="form.get(section.controlName)?.invalid && form.get(section.controlName)?.touched">
                                Invalid data.
                            </span>
                        </div>
                    </ng-container>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="form.invalid" data-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
</div>




<!-- Modal Editar -->
<div class="modal fade" id="editModal" #editModal tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Edit {{title}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form"  [formGroup]="editForm" (ngSubmit)="onSubmit()">
                    <ng-container *ngFor="let section of formSections">
                               <div class="form-group col-md-12">
                                <label for="{{ section.controlName }}">{{ section.title }}</label>
                                <ng-container *ngIf="section.type !== 'checkbox' && section.type !== 'file'">
                                    <ng-container *ngIf="section.type === 'select'">
                                        <ng-select [items]="section.selectOptions" notFoundText="No se encontraron datos"
                                        [formControlName]="section.controlName" bindLabel="name" bindValue="id"
                                            [placeholder]="section.title" [(ngModel)]="section.selectedItem">
                                        </ng-select>
                                    </ng-container>
                                    <input *ngIf="section.type === 'text' || section.type === 'number'" [type]="section.type" class="form-control" [formControlName]="section.controlName"
                                        [placeholder]="section.title">
                                </ng-container>
                                <ng-container *ngIf="section.type === 'checkbox'">
                                    <div class="form-check">
                                      <input class="form-check-input" type="checkbox" [formControlName]="section.controlName">
                                      <label class="form-check-label">{{ section.title }}</label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="section.type === 'radio'">
                                    <div *ngFor="let option of section.radioOptions">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" [formControlName]="section.controlName" [id]="option.value" [value]="option.value">
                                        <label class="form-check-label" [for]="option.value">{{ option.label }}</label>
                                      </div>
                                    </div>
                                  </ng-container>
                                <ng-container *ngIf="section.type === 'file'">
                                    <input type="file" class="form-control" [formControlName]="section.controlName">
                                </ng-container>
                                <span class="text-danger mx-1"
                                    *ngIf="editForm.get(section.controlName)?.invalid && editForm.get(section.controlName)?.touched">
                                    Invalid data.
                                </span>
                            </div>
                    </ng-container>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="update()" data-dismiss="modal"
                    [disabled]="editForm.invalid || !editForm.touched">Save</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Eliminar -->

<div class="modal fade" id="deleteModal" #deleteModal tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <i class="fa fa-trash trash"></i>
                <br>
                You're sure you want to delete?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal"
                    (click)="confirmDelete()">Delete</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Autorizar-->
<div class="modal fade" id="authorizeModal" #editModal tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Authorize ONU</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Contenido temporal
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-success">Authorize</button>
            </div>
        </div>
    </div>
</div>