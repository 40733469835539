import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss']
})
export class NumericComponent implements OnInit {

  @Input() data: TipoInput = {} as any;
  @Input() value: any = "";
  @Input() formData: IFormData = {vars:{}, error: {}};
  @Output() emitter: EventEmitter<any> = new EventEmitter();
  // @Output() outdata = new EventEmitter<{name: string, value: string, isInv
  

  ngOnInit(): void { }

  HandlerOnKey(event: any) {
    delete this.formData.error[this.data.Campo];
  }
  
  HanderOnChange(event: any) {
    delete this.formData.error[this.data.Campo];
    this.formData.vars[this.data.Campo] = event.target.value;
  }
}
