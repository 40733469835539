import { Component, Input } from '@angular/core';
import { Chart } from 'node_modules/chart.js/auto';

@Component({
  selector: 'app-chart-multiple',
  templateUrl: './chart-multiple.component.html',
  styleUrls: ['./chart-multiple.component.scss'],
})
export class ChartMultipleComponent {
  @Input() idChart: any;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() title2!: string;
  @Input() subtitle2!: string;
  @Input() data: any;
  @Input() data2: any;
  @Input() labels: any;
  @Input() type: any;
  @Input() color!: string;
  @Input() color2!: string;
  @Input() cant: any;

  ngOnInit() {}

  ngAfterViewInit() {
    this.chart1();
  }

  chart1() {
    new Chart(this.idChart, {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: [
          {
            label: this.subtitle,
            data: this.data,
            backgroundColor: this.color,
            borderColor: this.color,
            borderWidth: 1,
          },
          {
            label: this.subtitle2,
            data: this.data2,
            backgroundColor: this.color2,
            borderColor: this.color2,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
}
