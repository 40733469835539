<h4>OLTs temperature</h4>
<hr />
<div class="row mb-3">
  <div class="col-md-4 mb-2">
    <ng-select
      [items]="olts"
      (change)="selectOlt()"
      notFoundText="No data found"
      placeholder="Select OLT"
      bindLabel="name"
      bindValue="id"
      [(ngModel)]="selectedOlt"
    >
    </ng-select>
  </div>
  <div class="col-md-4 mb-2">
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        placeholder="IP, name, address"
        name="filters"
        [(ngModel)]="filters"
      />
    </div>
  </div>

  <div class="col-md-3 mb-2">
    <ng-select
      [items]="[{name: 'Weekly', value: 1}, {name: 'Monthly', value: 2}, {name: 'Yearly', value: 3}]"
      (change)="selectFormat()"
      notFoundText="No data found"
      placeholder="Select Time Format"
      bindLabel="name"
      bindValue="value"
      [(ngModel)]="selectedFormat"
    >
    </ng-select>
  </div>

  <div class="col-md-1 mb-2">
    <a class="btn btn-primary text-white" (click)="refresh()"
      ><i class="fa fa-refresh"></i
    ></a>
  </div>
</div>

<div class="mb-5">
  <div *ngIf="loading; else contentList" class="div-centrado">
    <app-loader></app-loader>
  </div>

  <ng-template #contentList>
    <div class="col-md-6 mb-5" *ngFor="let item of itemsOlts | filter : filters">
      <app-chart
        idChart="{{ item.id }}"
        title="{{ item.name }}"
        subtitle="{{ item.name }} {{ temperature }} °C"
        type="line"
        [data]="getGraphData(item.temperatureArray).temps"
        [labels]="getGraphData(item.temperatureArray).labels"
        (click)="mostrar(true, item.name, item.idOlt)"
        data-toggle="modal"
        data-target="#exampleModal"
      >
      </app-chart>
      <hr />
      <!-- Modal -->
      <div
        *ngIf="show"
        class="modal fade bd-example-modal-lg"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-large modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title title" id="exampleModalLabel">
                {{ title }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="clearModal()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <app-chart
                    idChart="{{ idRand() }}"
                    subtitle="{{ title }} {{ temperature }} °C"
                    type="line"
                    [data]="[5, 12, 23, -2, 10, 37, -14]"
                    [labels]="labels"
                  >
                  </app-chart>
                </div>
                <div class="col-md-6">
                  <app-chart
                    idChart="{{ idRand() }}"
                    subtitle="{{ title }} {{ temperature }} °C"
                    type="line"
                    [data]="[5, 12, 23, -2, 10, 37, -14]"
                    [labels]="labels"
                  >
                  </app-chart>
                </div>
                <div class="col-md-6">
                  <app-chart
                    idChart="{{ idRand() }}"
                    subtitle="{{ title }} {{ temperature }} °C"
                    type="line"
                    [data]="[5, 12, 23, -2, 10, 37, -14]"
                    [labels]="labels"
                  >
                  </app-chart>
                </div>
                <div class="col-md-6">
                  <app-chart
                    idChart="{{ idRand() }}"
                    subtitle="{{ title }} {{ temperature }} °C"
                    type="line"
                    [data]="[5, 12, 23, -2, 10, 37, -14]"
                    [labels]="labels"
                  >
                  </app-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
