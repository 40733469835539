<div  class="card bd-0" style="height: 50vh; border-radius: 15px; overflow: hidden;">

    <div class="card-header d-flex align-items-center justify-content-between border-0">
      <h6 class="mg-b-0 tx-24 tx-bold"><i *ngIf="icon" class="{{icon}}" aria-hidden="true"></i> {{ cardTitle }}</h6>
      <div class="card-option tx-24">
        <!-- <button class="opt-btn" (click)="getData()"><i class="icon ion-ios-refresh-empty lh-0"></i></button> -->

        <div *ngIf="this.filter && type == 'table'">
          <input type="text" class="form-control" placeholder="OLT Name, Board/Port, Power, Possile cause" name="filters" [(ngModel)]="filterText">
        </div>

        <div *ngIf="this.filter && this.info.length && type != 'table'" class="dropdown" >
          <button class="btn header-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
            Filter
          </button>
          <div class="dropdown-menu" style="transform: translateX(0);"> 
            <a class="dropdown-item" style="cursor: pointer;" (click)="emitData({id: 'Todos', name: ''})">All</a>
            <a *ngFor="let olt of info" class="dropdown-item" style="cursor: pointer;" (click)="emitData(olt)">{{olt.name}}</a>

          </div>
        </div>
      </div><!-- card-option -->
    </div><!-- card-header -->

    <div class="card-body bg-gray-200 border border-secondary overflow-scroll bg-light p-0">

      
      <div *ngIf="!info.length" class="div-centrado w-100 h-100 d-flex justify-content-center align-items-center">
        <!-- <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div> -->

        <p
        style="text-align: center; font-weight: bold; font-size: 16px"
        
        >
        No results
        </p>

      </div>


      <div *ngIf="filteredOlt.name && info.length" class="bg-white w-100 h-100 d-flex flex-column justify-content-around align-items-center">

        <img src="/assets/img/olt.jpeg" alt="OLT">

        <h4 class="px-5" style="cursor: pointer;" (click)="redirect(filteredOlt)">{{filteredOlt.name}}</h4>
      </div>

  

      <ng-container *ngIf="type != 'table' && info.length && !filteredOlt.name">

        <ng-container *ngIf="info[0].info">

          <div *ngFor="let item of info" style="cursor: default;" class="item d-flex flex-column align-items-start justify-content-between border-top border-bottom border-secondary m-0 flex-wrap p-4">
       
            <h3 class="tx-20 "> <i *ngIf="cardTitle == 'Info'" class="fa fa-user" aria-hidden="true"></i> {{item.name}}</h3>
            <p>{{item.info}}</p>
          </div>

        </ng-container>

        <ng-container *ngIf="!info[0].info">

          <div *ngFor="let item of info" class="item d-flex flex-column align-items-start justify-content-between border-top border-bottom border-secondary m-0 flex-wrap p-4" (click)="redirect(item)">
       
            <h3 class="tx-20 "> <i *ngIf="cardTitle == 'Info'" class="fa fa-user" aria-hidden="true"></i> {{item.name}}</h3>
            <p>{{item.info}}</p>
          </div>

        </ng-container>


      </ng-container>

      
        <table *ngIf="type == 'table' && info.length && !filteredOlt.length" class="table table-striped table-hover" id="table">

            <thead>

                <tr>

                    <th>OLT Name</th>
                    <th>Board/Port</th>
                    <th>ONUs</th>
                    <th>LOS</th>
                    <th>Power</th>
                    <th>Possible cause</th>
                    <th>Since</th>

                </tr>

            </thead>

            <tbody>
            <!-- Tabla -->
            <tr class="valign-center" *ngFor="let item of info">

                <td>{{item.oltName}}</td>
                <td><a [routerLink]="['/dashboard/diagnostics']" [queryParams]="{id: item.oltName, path: 'home'}">{{item.boardPort}}</a></td>
                <td>{{item.onus}}</td>
                <td>{{item.los}}</td>
                <td>{{item.power}}</td>
                <td>{{item.cause}}</td>
                <td>{{item.since}}</td>

            </tr>
            </tbody>
        </table>


    </div><!-- card-body -->
</div>
