<div class="profile-wrapper" >
      <app-new-user-sidebar class="fs-nus" [param]="cdata"></app-new-user-sidebar>
</div>

<!-- <div class="row row-sm">
  <div class="col-3">
    <div class="card bd-primary">
      <div class="card-header bg-primary tx-white">
        Foto de Perfil
      </div>
      <div class="card-body tx-center">
        <img src="/assets/img/img1.jpg" class="wd-100 rounded-circle" alt="">
        <div class="mg-t-20">
          <a class="btn btn-primary pd-x-20 mg-r-2">Editar</a>
          <a class="btn btn-secondary pd-x-20">Cambiar</a>
        </div>
      </div>
    </div>

    <div class="card bd-primary mg-t-20">
      <div class="card-header bg-primary tx-white">
        Habilidades
      </div>
      <div class="list-group list-group-flush list-group-skills">
        <div class="list-group-item">
          <span>HTML5</span>
          <a href="">&times;</a>
        </div>
        <div class="list-group-item">
          <span>CSS3</span>
          <a href="">&times;</a>
        </div>
        <div class="list-group-item">
          <span>Javascript</span>
          <a href="">&times;</a>
        </div>
        <div class="list-group-item">
          <span>PHP</span>
          <a href="">&times;</a>
        </div>
        <div class="list-group-item">
          <span>Photoshop</span>
          <a href="">&times;</a>
        </div>
        <div class="list-group-item">
          <span>Bootstrap</span>
          <a href="">&times;</a>
        </div>
        <div class="list-group-item">
          <span>Angular</span>
          <a href="">&times;</a>
        </div>
        <div class="list-group-item">
          <span>React</span>
          <a href="">&times;</a>
        </div>
      </div>
    </div>

  </div>
  <div class="col-9">

    <div class="card bd-primary">
      <div class="card-header bg-primary tx-white">
        Informacion de Inicio de Sesión
        <div class="card-body">
          <div class="form-group row align-items-center">
            <label class="mg-b-0 col-3 tx-right">Usuario</label>
            <div class="col-6">
              <input type="text" class="form-control" placeholder="Ingrese Usuario" value="">
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="mg-b-0 col-3 tx-right">Correo</label>
            <div class="col-6">
              <input type="email" class="form-control" placeholder="Ingrese Correo" value="">
            </div>
          </div>
          <div class="form-group row align-items-center mg-b-0">
            <label class="mg-b-0 col-3 tx-right">Contraseña</label>
            <div class="col-6">
              <a href=""> Cambiar Contraseña </a>
            </div>
          </div>
        </div>
      </div>

      <div class="card bd-primary mg-t-20">
        <div class="card-header bg-primary tx-white">
          Información Personal
        </div>
        <div class="card-body">
          <div class="form-group row align-items-center">
            <label class="mg-b-0 col-3 tx-right">Nombre</label>
            <div class="col-6">
              <input type="text" class="form-control" placeholder="Ingrese Nombre" value="">
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="mg-b-0 col-3 tx-right">Apellido</label>
            <div class="col-6">
              <input type="text" class="form-control" placeholder="Ingrese Apellido" value="">
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="mg-b-0 col-3 tx-right">Dirección</label>
            <div class="col-6">
              <input type="text" class="form-control" placeholder="Ingrese Dirección" value="">
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="mg-b-0 col-3 tx-right">Portfolio URL</label>
            <div class="col-6">
              <input type="text" class="form-control" placeholder="Enter URL" value="">
            </div>
          </div>
          <div class="form-group row">
            <label class="mg-b-0 col-3 tx-right mg-t-10">Acerca de Ti</label>
            <div class="col-8">
              <textarea class="form-control" rows="4"
                placeholder="Escribe una breve descripción sobre ti..."></textarea>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <hr class="mg-y-30">

  <button class="btn btn-success pd-x-20 mg-r-2"> Guardar Cambios </button>
  <button class="btn btn-secondary pd-x-20"> Desactivar Mi Cuenta </button>

  <hr class="mg-t-30">
</div> -->
<!-- card-header -->

<!-- <app-avatar-generic  [Avatar]="'test'" (ValueSalida)="GetImgURL($event)"></app-avatar-generic> -->

<!-- card-body -->

<!-- card -->

<!-- card-header -->

<!-- list-group-item -->

<!-- list-group-item -->

<!-- list-group-item -->

<!-- list-group-item -->

<!-- list-group-item -->

<!-- list-group-item -->

<!-- list-group-item -->

<!-- list-group-item -->

<!-- list-group -->

<!-- card -->

<!-- col-3 -->

<!-- card-header -->

<!-- col-8 -->

<!-- form-group -->

<!-- col-8 -->

<!-- form-group -->

<!-- col-8 -->

<!-- form-group -->

<!-- card-body -->

<!-- card -->

<!-- card-header -->

<!-- col-8 -->

<!-- form-group -->

<!-- col-8 -->

<!-- form-group -->

<!-- col-8 -->

<!-- form-group -->

<!-- col-8 -->

<!-- form-group -->

<!-- col-8 -->

<!-- form-group -->

<!-- card-body -->

<!-- card -->

<!-- col-9 -->

<!-- row -->
