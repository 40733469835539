
<div>
    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>


    <ng-template #contentList>

        
    <div class="col-md-12 mb-3 aling-items-center">
        <label class="control-label">Export</label>

        <app-export [info]="onus" [columns]="['name', 'olt_name']"></app-export>

    </div>
    <app-lista [buttonsHide]="buttonsHide" [title]="modalTitle"  [array]="array" [endPoint]="endPoint" [form]="form" [editForm]="editForm" (formEditEmitter)="receiveEdit($event)" (itemIdEmitter)="receiveID($event)" > </app-lista>
    </ng-template>
</div>

<app-modal [title]="modalTitle" [formSections]="formSections" [id]="id" [endPoint]="endPoint" [editForm]="editForm" [form]="form" (dataEmitter)="receiveData($event)"></app-modal> 
